import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IntroImage from '../../public/IntroImage';

export default function About() {
    return(
    <Stack justifyContent={"center"} sx={{width:'100%', alignItems:"center"}} spacing={4}>
      <IntroImage hideImage={true}/>
      <Typography variant={"body1"}>
        Oduvek sam želeo da budem menađer. Mada nikada nisam znao šta to zapravo znači, pa ni kako se tačno piše. Ali mislio sam da je to nešto dobro, pa sam rešio da to i postanem.
        <p>Razmišljao sam kakav bih ja to želeo da budem menađer. Pored programiranja, razumem se pomalo i u fudbal, pa sam rešio da spojim lepo i korisno i isprogramiram da budem fudbalski menađer.
        I podelio sam ovu igru sa svima koji bi da budu menađeri i upravljaju svim najboljim fudbalerima Evrop, baš onima koji će se naći na Evropskom prvenstvu!</p>
        <p>Možete u sklopu budžeta, pardon, buđeta kupovati sebi drage fudbalere, ali buđet vam nije preveliki, pa ćete morati da kupujete i neke za vas u ovom trenutku anonimne fudbalere iz egzotičnih fudbalskih zemalja.
        Mogao bih da nam uvećam buđet, ali bolje da upoznamo fudbalske znalce i štaviše navijamo za njih jer će nam donositi bodove.</p>
        <p>Kako bude teklo prvenstvo i nama manje poznati fudbalski magovi budu pakovali kofere za povratak u svoju egzotičnu domovinu, buđet će se povećavati i moći ćemo da dovodimo i skuplje igrače.</p>
        Pored transfera, možete upravljati formacijom, odabrati najboljih 11, kao i kapitena koji će donositi duple bodove.<br/>
        <p>Kreirajte lige, pozovite svoje prijatelje da se pridruže i odmerite sa njima svoje menađerske sposobnosti.</p>

        <p> Ovo je beta verzija igre. Za sve probleme, predloge i sugestije, pišite na: <b>vladimir.jovanovic@aparteko.com</b></p>

        <p>Menađer Vladimir Jovanović</p>

      </Typography>
    </Stack>)
}