import axios from 'axios';
import React from 'react';
// import logo from './logo.svg';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { useAppContext } from './context/AuthContext';


const auth: boolean = false;

function App() {

      const { authData, deleteAuthData } = useAppContext();

      axios.interceptors.response.use(
        (response) => {
          if (response.status === 401) {
            deleteAuthData();
          }
          return response;
        },
        (error) => {
          if (error.response?.status === 401) {
            deleteAuthData();
          }
          return Promise.reject(error);
        }
      );

      return authData && authData.token ? <PrivateRoute /> : <PublicRoute />;
}

export default App;
