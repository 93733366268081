import EventService, { EventServiceType } from "../../../service/event/EventService";
import Stack from '@mui/material/Stack';
import RoundShortDto from "../../../model/event/RoundShortDto";
import MatchDto from "../../../model/match/MatchDto";
import RoundCard from "./RoundCard";
import {useState, useEffect} from "react";
import MatchCard from "./MatchCard";
import './schedule.css';

import { Typography } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll'
import RoundPoints from "../../../model/round/RoundPoints";

interface ScheduleProps {
  initRound: number;
  onRoundChanged?: Function;
  points?: number;
  roundPoints?: RoundPoints [];
}

export default function Schedule(props: ScheduleProps) {

  const { allRoundsShort, requestMatchesForRound}: EventServiceType = EventService();

  const [matches, setMatches] = useState<MatchDto[]>([]);
  const [activeRound, setActiveRound] = useState<number>(props.initRound);

  const handleRoundClick = (roundId: number) => {
    
    requestMatchesForRound(roundId, onMatchesRetrieved );

    if (props.onRoundChanged !== undefined){
      props.onRoundChanged(roundId);
    }
  }

  const onMatchesRetrieved = (r: number, m: MatchDto[]) => {
    setActiveRound(r);
    setMatches(m);
  }

  useEffect(() => {
      if (props.initRound >= 0){
        setActiveRound(props.initRound);
        handleRoundClick(props.initRound);
      }
    },[props.initRound]
  );

  const getFinishedRoundPoints = (roundId: number): number|undefined=>{
    if (props.roundPoints === undefined)
      return undefined;

    let rps: RoundPoints[]  = props.roundPoints!.filter(rp => rp.round === roundId);
    if (rps.length === 0)
      return 0;

    return rps[0].points;
  }

  return (
  <Stack direction={"column"} sx={{boxShadow:"3px 3px 5px 6px #ccc", backgroundColor:'whitesmoke',
    width:'100%', display:'inline-grid', justifyContent:'center', alignItems:'center', alignContent:'center', textAlign:"center"}}>
    <ScrollContainer>
      <Stack direction={"row"} spacing={2} 
        sx={{justifyItems:'center', padding:'5px', textAlign:'center', width:'100%', justifyContent:'center', whiteSpace: 'nowrap', display: 'inline-block'}}>
        {
          allRoundsShort &&
          allRoundsShort!.sort((a, b) => a.ord - b.ord).map((r: RoundShortDto)=> (
            <RoundCard isSelected={r.id ===activeRound} onClick={handleRoundClick} key={r.id} round={r} livePoints={props.points} finishedPoints={getFinishedRoundPoints(r.id)}/>
          )
        )}
      </Stack>
    </ScrollContainer>
    <ScrollContainer>
      <Stack direction={"row"} spacing={2} 
        sx={{padding: '5px', textAlign:'center', justifyContent:'center', whiteSpace: 'nowrap', display: 'inline-block', cursor:'grab'}}>
        {         
          (matches.length > 0 && activeRound >= 0) &&
          (
            matches.sort((a, b) => a.id - b.id).filter((m:MatchDto) => m.host.id !== 109).map((m: MatchDto)=> (
              <MatchCard key={m.id} match={m}/>
            ))
          )
        }

      </Stack>
    </ScrollContainer>
  </Stack>
  );
}