const getUrl = () => {
  let backendAddress;
  if (process.env.NODE_ENV === "production") {
    // backendAddress = `http://127.0.0.1:8080`;
    
    backendAddress = 'https://staging.slagalica-online.com:8881';
  } else {
    backendAddress = `http://127.0.0.1:8080`;
    //backendAddress = `http://192.168.1.4:8080`;
    // backendAddress = 'https://staging.slagalica-online.com:8881';
  }
  return `${backendAddress}`;
};
const Endpoints = {
  AUTHENTICATE: getUrl() + "/auth/login",
  REGISTRATION: getUrl() + "/auth/signup",
  RESET_PASSWORD: getUrl() + "/reset-password",
  LOGOUT: getUrl() + "/user/logout",
  USER: getUrl() + "/user/my-info",
  TEAM: getUrl() + "/team",
  TRANSFERS: getUrl() + "/transfer",
  EVENT: getUrl() + "/event",
  LEAGUE: getUrl() + "/league",
  PLAYER_ACTION: getUrl() + "/player-action",
  ADMIN: getUrl() + "/admin",
  SPORT_TEAM: getUrl() + "/sport-team",
};

export default Endpoints;