import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import UpdateMatchDto from "../../model/match/UpdateMatchDto";
import CreatePlayerActionDto from "../../model/playerpoints/CreatePlayerActionDto";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";
import UpdateMatchPeriodDto from "../../model/match/UpdateMatchPeriodDto";

interface AdminControllerType {
  axiosSaveMatch: any;
  axiosGetLiveMatches: any;
  axiosGetCurrentRoundMatches: any;
  axiosStartMatch: any;
  axiosChangeMatchPeriod: any;
  axiosEndMatch: any;
  axiosGetSportTeamPlayers: any;
  axiosPostPlayerAction: any;
  axiosGetAllPlayerActions: any;
  axiosPostRemoveAction: any;
  axiosStartRound: any;
  axiosStopRound: any;
}

export const EVENT_ID: number = 1;

export function AdminController(): AdminControllerType {
  const { authData } = useAppContext();

  const axiosGetLiveMatches = () => {
    return axios.get(Endpoints.ADMIN + '/get-live-matches',  axiosConfig(authData!.token));
  };

  const axiosGetCurrentRoundMatches = () => {
    return axios.get(Endpoints.ADMIN + '/get-matches-for-current-round', axiosConfig(authData!.token));
  };
  
  const axiosGetAllPlayerActions = (matchId: number) => {
    return axios.get(Endpoints.PLAYER_ACTION + '/' + matchId + '/get-all-actions', axiosConfig(authData!.token));
  };

  const axiosSaveMatch = (m: UpdateMatchDto) => {
    return axios.post(Endpoints.ADMIN + '/' + m.id + '/update-match', m, axiosConfig(authData!.token));
  };
  
  const axiosStartMatch = (id: number) => {
    return axios.post(Endpoints.ADMIN + '/' + id + '/start-match', {}, axiosConfig(authData!.token));
  };

  const axiosChangeMatchPeriod = (id: number, ump: UpdateMatchPeriodDto) => {
    console.log(ump);
    return axios.post(Endpoints.ADMIN + '/' + id + '/change-match-period', ump, axiosConfig(authData!.token));
  };

  const axiosEndMatch = (id: number) => {
    return axios.post(Endpoints.ADMIN + '/' + id + '/end-match', {}, axiosConfig(authData!.token));
  };

  const axiosStartRound = (roundId: number) => {
    return axios.post(Endpoints.ADMIN + '/' + EVENT_ID  + '/' + roundId + '/start-round', {}, axiosConfig(authData!.token));
  };

  const axiosStopRound = (roundId: number) => {
    return axios.post(Endpoints.ADMIN + '/' + EVENT_ID  + '/'  + roundId + '/stop-round', {}, axiosConfig(authData!.token));
  };

  const axiosPostRemoveAction = (actionId: number) => {
    return axios.post(Endpoints.PLAYER_ACTION + '/' + actionId + '/delete-player-action', {}, axiosConfig(authData!.token));
  };

  const axiosPostPlayerAction = (dto: CreatePlayerActionDto) => {
    return axios.post(Endpoints.PLAYER_ACTION + '/' + dto.matchId + '/add-player-action', dto, axiosConfig(authData!.token));
  };

  const axiosGetSportTeamPlayers = (id: number) => {
    return axios.get(Endpoints.SPORT_TEAM + '/' + id + '/get-players', axiosConfig(authData!.token));
  };

  return { axiosSaveMatch, axiosGetLiveMatches, axiosGetCurrentRoundMatches, axiosStartMatch, axiosChangeMatchPeriod, axiosEndMatch, axiosGetSportTeamPlayers, axiosPostPlayerAction, axiosGetAllPlayerActions, axiosPostRemoveAction, axiosStartRound, axiosStopRound};
}

export type { AdminControllerType};
