import TransferService, { TransferServiceType } from "../../../service/transfer/TransferService";
import MaterialTable from 'material-table';
import {useState, useEffect} from 'react';
import EnumPlayerPosition from "../../../utils/enum/EnumPlayerPosition";
import { Box, createTheme, styled, ThemeProvider, Typography } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import FilterTeamSelection from "./FilterTeamSelection";
import PositionToggle from "./PositionToggle";
import { tableIcons } from "../../../system/material-table-utils/MaterialTableUtils";
import PlayerDto from "../../../model/player/PlayerDto";
import PriceSlider from "./PriceSlider";
import AddBox from "@material-ui/icons/AddBox";
import Remove from "@material-ui/icons/Remove";
import ChosenPlayers from "./ChosenPlayers";
import { getCountyFlagAvatar } from "../../../utils/CountryColors";
import TransferStatus from "./TransferStatus";
import EventService, { EventServiceType } from "../../../service/event/EventService";
import Schedule from "../schedule/Schedule";
import TeamDto from "../../../model/team/TeamDto";
import RoundSquadDto from "../../../model/team/RoundSquadDto";
import StatsDialog from "../team/player/StatsDialog";
import TotalPlayerStatsDto from "../../../model/playerpoints/TotalPlayerStatsDto";
import PlayerPointsService, { PlayerPointsServiceType } from "../../../service/playerpoints/PlayerPointsService";
import { useMediaQuery } from 'react-responsive';
import { Theme } from "@mui/material";
import { responsiveProperty } from "@mui/material/styles/cssUtils";

const tableTheme = createTheme( {
  overrides: {
    MuiAvatar: {
      root:{
        height: '25px',
        width: '25px',
        boxShadow: '0 0 2px black'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 2px 0px 2px',
      }
    }
  }
});

// const ResponsiveStyle = styled('th')(({ theme }) => ({
//   root: {
//     [theme.breakpoints.down('xs')]: {
//       padding: '0px 3px 0px 3px',
//     },
//   },
// }));


interface TransfersProps{
  team: TeamDto | undefined | null;
  postRoundSquad: Function;
}

export default function Transfers2(props: TransfersProps) {

  const { allTeams, allPlayers, costRange}: TransferServiceType = TransferService();
  const { nextRound }: EventServiceType = EventService();
  
  const [chosenPlayers, setChosenPlayers] = useState<PlayerDto[]>([]);
  const [previousSquad, setPreviousSquad] = useState<PlayerDto[]>([]);
  const [transfersUsed, setTransfersUsed] = useState<number>(0);
  const [positionFilter, setPositionFilter] = useState<EnumPlayerPosition | null>(null);
  const [teamsFilter, setTeamsFilter] = useState<string[]|undefined>([]);
  const [positionsOrder, setPositionsOrder] = useState<string[]>([EnumPlayerPosition.GOALKEEPER, EnumPlayerPosition.DEFENDER, EnumPlayerPosition.MIDFIELDER, EnumPlayerPosition.FORWARD]);
  const [sliderValues, setSliderValues] = useState<number[] | undefined>();
  const [stats, setStats] = useState<TotalPlayerStatsDto | undefined>(undefined);

  const { requestPlayerStats} : PlayerPointsServiceType = PlayerPointsService();
  const [openStatsDialog, setOpenStatsDialog] = useState<boolean>(false);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isSmallMobile = useMediaQuery({ query: `(max-width: 550px)` });

  const handlePositionChange = (position: string | null) => {
    setPositionFilter(getPositionFilter(position));
  }

  const handleSelectionChange = (teams: string[] | undefined) =>{
    setTeamsFilter(teams);
  }

  const getPositionFilter = (value: string | null): EnumPlayerPosition | null =>{
    switch (value) {

      case null:
        return null;
      case 'GK':
        return EnumPlayerPosition.GOALKEEPER;
      case 'DF':
        return EnumPlayerPosition.DEFENDER;
      case 'MF':
        return EnumPlayerPosition.MIDFIELDER;
      case 'FW':
        return EnumPlayerPosition.FORWARD;
      default:
        return null;
    }
  }

  const handleAdd = (rowData: PlayerDto) =>{
    setChosenPlayers(prev => [...prev, rowData]);
  }

  const handleRemove = (rowData: PlayerDto) =>{
    setChosenPlayers(chosenPlayers.filter(item => item.id !== rowData.id));
  }

  const sortPositions = (a: EnumPlayerPosition, b: EnumPlayerPosition): number => {
    let aString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(a)];
    let bString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(b)];

    return positionsOrder.indexOf(aString) - positionsOrder.indexOf(bString);
  }

  const handleOrderChanged = (orderedColumnId: number, order: string) => {
    if (orderedColumnId === 0 && order === 'asc'){
      let first: string = (positionsOrder.shift())!;
      positionsOrder.push(first);
      setPositionsOrder(positionsOrder);
    }
  }

  const sendRoundSquad = () => {
    // console.log('sendRoundSquad', props.team!);
    props.postRoundSquad(nextRound!.id, props.team!.id, chosenPlayers.map(p => p.id));
  }

  const handleSliderChange = (values: number[]) => {
    setSliderValues(values);
  }

  useEffect(() => {


    console.log(nextRound);
    if (!props.team || !props.team!.squads || !nextRound)
      return;
      
    let rs: RoundSquadDto[] = props.team!.squads.filter((s) => s.round.id === nextRound!.id);

    if (rs.length === 0)
      return;

    console.log(rs[0].players);

    if (!(rs[0].players) || (rs[0].players === undefined)){
      console.log('no players', nextRound, props.team);
      return;
    }
    // let cp: PlayerDto[] = [];

    if (rs[0] && rs[0].players && rs[0].players.length > 0){
      setChosenPlayers([]);
      rs[0].players.forEach((player: PlayerDto) => {
          handleAdd(allPlayers.filter(p => p.id === player.id)[0]);
      });
    }

    //   let f: PlayerDto = allPlayers.filter(p => p.id === player.id)[0];
    //   if (f && f !== undefined)
    //     cp.push(f);
    // });
    // setChosenPlayers(cp);

    console.log(rs[0]);
    let ids: number [] = rs[0].players.map(rsp => rsp.id);
    console.log(allPlayers);
    setChosenPlayers(allPlayers.filter(p => ids.includes(p.id)));
    // setChosenPlayers([...rs[0].players]);
    setPreviousSquad([...rs[0].players]);
    setTransfersUsed(rs[0].transfersUsed);

  },[props.team, nextRound, allPlayers]);

  
  const getPlayerPoints = (e: any, id: number) => {
    requestPlayerStats(id, onStatsReceived);
    setOpenStatsDialog(true);
  }

  const onStatsReceived = (s: TotalPlayerStatsDto) => {
    setStats(s);
    setOpenStatsDialog(true);
  }

  const handleStatsClosed = () => {
    setOpenStatsDialog(false);
  }

  const getFilteredRows = ()  => {
    let filterredRows =  
    allPlayers
    .filter(
      row => {
        return positionFilter == null || row.playerPosition === Object.keys(EnumPlayerPosition)[Object.values(EnumPlayerPosition).indexOf(positionFilter)];
      } )
    .filter(     
      row => {
        return teamsFilter === undefined || teamsFilter.length <= 0 || teamsFilter.indexOf(row.teamName) > -1;
      } )
    .filter(
      row => {
        return sliderValues === undefined || (row.cost >= sliderValues[0] && row.cost <= sliderValues[1])
      })
    ;

    return filterredRows;
  }

    if (!nextRound) return <Typography>Trenutno nije otvoren transfer rok.</Typography>

    return (
      <div style={{ maxWidth: '100%' }}>
      <Schedule initRound={nextRound.id}/>
      <TransferStatus transfersUsed={transfersUsed} nextRound={nextRound} previousSquad={previousSquad} chosenPlayers={chosenPlayers} sendRoundSquad={sendRoundSquad}/>
      <Box>
        <ChosenPlayers players={chosenPlayers} onPlayerRemoved={handleRemove}/>
        <Stack direction={"row"}
            justifyContent="space-between" display="flex" alignItems="flex-end"
            flexWrap="wrap"
            sx={{paddingTop:'20px', paddingBottom:'20px', width:'100%'}}>
          <PositionToggle onPositionChange={handlePositionChange}/>
          <FilterTeamSelection onSelectionChange={handleSelectionChange} allNames = {allTeams.map((t)=>t.name)} allTeams = {allTeams}/>
          <PriceSlider minValue={costRange[0]} maxValue={costRange[1]} onSliderChange={handleSliderChange}/>
        </Stack>
      </Box>
        <ThemeProvider theme={tableTheme}>
          {/* <ResponsiveStyle> */}
          <MaterialTable 
            icons={tableIcons}
            // style={{maxWidth:'95vw'}}
            onRowClick={(event, rowData) => getPlayerPoints(event, rowData!.id)}
            options={{
              pageSize: 30, 
              pageSizeOptions: [30, 50, 100, 200],
              sorting: true,
              thirdSortClick: false,
              actionsColumnIndex: -1, 
              padding: 'default',
              rowStyle: (rowData:PlayerDto) => ({
                backgroundColor: (chosenPlayers.includes(rowData)) ? '#7F7':'#FFF',
                height: "20px"
              }),
            }}
            localization={{
              header: { actions: '+/-' }
            }}

            onOrderChange={(columnId:number, order:string)=>{ handleOrderChanged(columnId, order)}}

            columns={[
              { 
                title: 'Pozicija', 
                render: (data: PlayerDto) => <div>{Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(data.playerPosition)]}</div>, 
                customSort: (a: PlayerDto, b: PlayerDto) => sortPositions (a.playerPosition, b.playerPosition),
              },
              { title: 'Ime', field: 'name', defaultSort: "asc" },
              { title: 'Tim', field: 'teamName', render: (rowData:PlayerDto) => 
                <Stack direction="row" spacing={2}>
                  {getCountyFlagAvatar(rowData.teamName)}
                  {!isMobile && <Typography>{rowData.teamName}</Typography>}
                </Stack>
              },
              { title: 'Cena', field: 'cost', align: "right", defaultSort: "desc" },
            ]}
            data={getFilteredRows()}
            title={isMobile?"":"Igrači"}
            
            actions={[
              (rowData: PlayerDto) =>          
              !(chosenPlayers.includes(rowData))?
              ({
                icon: () => <AddBox />,
                tooltip: 'Dodaj',
                onClick: () => handleAdd(rowData),
              }):
              ({
                icon: () => <Remove/>,
                tooltip: 'Izbriši',
                onClick: () => handleRemove(rowData),
              })
            ]}
            
          />
          
        <StatsDialog open={openStatsDialog} onDialogClosed={handleStatsClosed} stats={stats}/>
        {/* </ResponsiveStyle> */}
        </ThemeProvider>
      </div>
    )
  }