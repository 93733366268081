import { EffectCallback, useEffect } from "react";
import MessageType from "./MessageType";

const axiosConfig = (token: string, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const handleSuccessMessage = (showMessage: Function, response: any) => {
  if (response.data.successes) {
    response.data.successes.forEach((e: any) => {
      showMessage(e.message, MessageType.SUCCESS);
    });
  } else {
    showMessage(response.toString(), MessageType.SUCCESS);
  }
};

const handleErrorMessage = (showMessage: any, error: any) => {
  if (error?.response?.status === 401) {
    showMessage("Uneti kredencijali nisu ispravni.", MessageType.ERROR);

  if (error?.response?.status === 409) {
    showMessage("Konflikt: " + error.response.data.message, MessageType.ERROR);
  }

  } else if (error?.response?.data?.message) {
    showMessage(error.response.data.message, MessageType.ERROR);
  } else {
    showMessage(error.toString(), MessageType.ERROR);
  }
};

const getFormattedDate = (date: Date): string => {
  return new Date(date).toLocaleString();
}

const useEffectOnce = (effect: EffectCallback) => {
  useEffect(effect, []);
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const existValue = (value: string): boolean => {
  return value && value !== "" ? true : false;
};

const getDateAndTimeAsString = (timeAsDate: Date): string => {

  return  timeAsDate.getDate() + "."+ (timeAsDate.getMonth()+1) + ". " + timeAsDate.getHours() + ":" + ('0'+timeAsDate.getMinutes()).slice(-2);
}

const getDateAsString = (timeAsDate: Date): string => {

  return timeAsDate.getDate() + "."+ (timeAsDate.getMonth()+1) + ".";
}

const getTimeAsString = (timeAsDate: Date): string => {

  return timeAsDate.getHours() + ":" + ('0'+timeAsDate.getMinutes()).slice(-2);
}

export {
  axiosConfig,
  handleSuccessMessage,
  handleErrorMessage,
  validateEmail,
  existValue,
  useEffectOnce,
  getFormattedDate,
  getDateAndTimeAsString,
  getDateAsString,
  getTimeAsString
};
