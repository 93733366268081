import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps{
    onCloseDialog: Function;
    open: boolean;
    info: { title: string, text: string};
}

export default function AlertDialog(props: AlertDialogProps) {

  const handleClose = () => {
    props.onCloseDialog();
  };

  return (

    <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {props.info.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.info.text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="warning" autoFocus>
            OK
        </Button>
        </DialogActions>
    </Dialog>
  );
}
