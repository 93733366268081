import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppContext } from "../../context/AuthContext";
import useStyles from "./BlockUIStyle";

export default function BlockUI() {
  const { blockUI } = useAppContext();

  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={blockUI}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
