import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import TotalPlayerStatsDto from "../../../../model/playerpoints/TotalPlayerStatsDto";
import { getCountryName12chr, getCountyFlagAvatar } from "../../../../utils/CountryColors";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MatchActionsPerPlayerDto from "../../../../model/playerpoints/MatchActionsPerPlayerDto";
import EnumMatchStatus from "../../../../utils/enum/EnumMatchStatus";
import PlayerMatchPointsDto from "../../../../model/playerpoints/PlayerMatchPointsDto";
import MatchPlayerStatsAccordion from "./MatchPlayerStatsAccordion";
import { sortByPlayerPosition, sortPositions } from "../../../../utils/Formation";

interface MatchPlayerStatsDialogProps{
    onDialogClosed: Function;
    open: boolean;
    stats: MatchActionsPerPlayerDto | null;
}

export default function MatchPlayerStatsDialog(props: MatchPlayerStatsDialogProps) {

    const getHomePlayerMatchPointsDto = (): PlayerMatchPointsDto[] => {
        if (!props.stats)
            return [];
        return sortByPlayerPosition(props.stats.playerStats.filter( (a) => a.player.teamName === props.stats!.match.host.name));
    }

    const getVisitorsPlayerMatchPointsDto = (): PlayerMatchPointsDto[] => {
        if (!props.stats)
            return [];
        return sortByPlayerPosition(props.stats.playerStats.filter( (a) => a.player.teamName === props.stats!.match.visitor.name));
    }

    const accordionStyle = {
        justifyContent:'space-between', 
        margin:"5px 0px", 
        minWidth:"250px",
        display:'flex', 
        '&.Mui-expanded': {
            minHeight: '16px', 
          },
          '.MuiAccordionSummary-content': {
            margin: '5px 0', 
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '5px 0', 
          },
        
        backgroundColor: (props.stats && props.stats.match.matchStatus === EnumMatchStatus.LIVE)? 'forestgreen':'lavender'
    }

    const handleClose = () => {
        props.onDialogClosed(true);
    };

    if (!props.stats)
        return (<Dialog open={props.open} onClose={handleClose}>
            <DialogContent>
            <DialogContentText>
                učitavanje...
            </DialogContentText>
            </DialogContent>
        </Dialog>);
    
    return (
        <Dialog open={props.open} maxWidth="md" onClose={handleClose}>
            <DialogContent dividers >
                <DialogContentText>
                    <Stack>
                        <Stack direction={"row"} spacing={2} sx={{alignItems:"center"}} >
                            {getCountyFlagAvatar(props.stats.match.host.name, {width:'40px', height:'40px'})}
                            
                            <Stack alignItems={"center"} spacing={3}>
                                <Typography variant='h4' noWrap>
                                    {+ props.stats.match.homeScore + " "}
                                    :
                                    {" " + props.stats.match.visitorsScore}
                                </Typography>
                            </Stack>
                            
                            {getCountyFlagAvatar(props.stats.match.visitor.name, {width:'40px', height:'40px'})}
                                
                        </Stack>
                    </Stack>


                </DialogContentText>
                <Stack direction='column' sx={{width:'100%'}}>

                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                            sx={accordionStyle}
                        >
                        <Stack sx={{width:'100%', justifyContent:'left', display:'grid'}}>
                            <Stack direction={"row"} alignItems={"left"} spacing={2}>
                            {getCountyFlagAvatar(props.stats.match.host.name, {maxWidth:'20px', maxHeight:'20px'})}
                            </Stack>
                        </Stack>
                        </AccordionSummary>
                        
                        <AccordionDetails sx={{ width: '100%' }}>
                            <Stack direction={"column"}>
                                {
                                    getHomePlayerMatchPointsDto().map((s)=> (
                                        <MatchPlayerStatsAccordion style={accordionStyle} live={(props.stats!.match.matchStatus === EnumMatchStatus.LIVE)} match={props.stats!.match} stats={s} ></MatchPlayerStatsAccordion>
                                            )
                                        )
                                }
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3b-content"
                        id="panel3b-header"
                        sx={accordionStyle}
                    >
                    <Stack sx={{width:'100%', justifyContent:'left', display:'grid'}}>
                        <Stack direction={"row"} alignItems={"left"} spacing={2}>
                        {getCountyFlagAvatar(props.stats.match.visitor.name, {maxWidth:'20px', maxHeight:'20px'})}
                        </Stack>
                    </Stack>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                        <Stack direction={"column"}>
                            {
                                getVisitorsPlayerMatchPointsDto().map((s)=> (
                                    <MatchPlayerStatsAccordion style={accordionStyle} live={(props.stats!.match.matchStatus === EnumMatchStatus.LIVE)} match={props.stats!.match} stats={s}></MatchPlayerStatsAccordion>
                                        )
                                    )
                            }
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                </Stack>
            </DialogContent>
            <DialogActions>
                
                <Button onClick={handleClose}
                        color="secondary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>    
    );
}
