enum EnumMatchPeriod {
    NOT_STARTED = "Uskoro", 
    HALF_1 = "1. pol", 
    HALFTIME = "poluvreme", 
    HALF_2 = "2. pol",
    AWAITING_ET = "ček. prod.",
    ET_1 = "1. prod",
    ET_2 = "ček. 2. prod.",
    AWAITING_PEN = "čel. pen.", 
    PENALTY_KIKCS = "Penali",
}

export default EnumMatchPeriod;