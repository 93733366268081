import { Avatar, Typography } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import PlayerDto from "../../../../model/player/PlayerDto";
import jerseyInside from "../../../../assets/img/jersey-inside.png";
import colorJersey from "../../../../assets/img/color-jersey.png";
import captainImg from "../../../../assets/img/captain.png";
import {createTheme} from "@material-ui/core";
import { ThemeProvider } from "@mui/styles";
import { CountryColors, getColorForCountry, getCountyFlagAvatar } from "../../../../utils/CountryColors";
import TintColor from "../../../../utils/TintColor";
import { useEffectOnce } from "../../../../utils/Utils";
import { useState, useRef, useEffect} from 'react';
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import PlayerPoints from "../../../../model/player/PlayerPoints";
import EnumMatchStatus from "../../../../utils/enum/EnumMatchStatus";
import StatsDialog from "./StatsDialog";
import PlayerPointsService, { PlayerPointsServiceType } from "../../../../service/playerpoints/PlayerPointsService";
import TotalPlayerStatsDto from "../../../../model/playerpoints/TotalPlayerStatsDto";

interface PlayerProps{
    player: PlayerDto;
    onSubstitute: Function;
    setDraggingActive: Function;
    isDraggingActive: boolean;
    isCaptain: boolean;
    points: PlayerPoints | null;
  }

  export default function Player(props: PlayerProps) {

  
  const { requestPlayerStats} : PlayerPointsServiceType = PlayerPointsService();

  const [jersreySrc, setJerseySrc] = useState<string | undefined>(undefined);
  const [nameColor, setNameColor] = useState<string | undefined>('black');
  const [points, setPoints] = useState<PlayerPoints | null>(props.points);
  const [stats, setStats] = useState<TotalPlayerStatsDto | undefined>(undefined);
  const [openStatsDialog, setOpenStatsDialog] = useState<boolean>(false);


  const theme = createTheme({
    typography: {
      fontSize: 10,
    },
    
  });


  const ref = useRef(null); // Initialize the reference
  
  useEffectOnce(() => {
    getJerseyColor();
  });

  useEffect(() => {
    setPoints(props.points);
  },[props.points]);


  const getJerseyColor = () => {

    let colors: typeof CountryColors = getColorForCountry(props.player.teamName);
    setNameColor(colors.text);
    let tint: TintColor = new TintColor(colorJersey, colors.jersey);
    tint.run().then(result => setJerseySrc(result.url));
  }

  const getJerseySrc = () : string => {
      return (jersreySrc === undefined)? jerseyInside: jersreySrc;
  }

  const getTypographyStyle = (small: boolean, additional?: {}) : React.CSSProperties => {
    return {fontSize:small?'70%':'100%', fontWeight:small?"bold":"normal",  color: nameColor, zIndex:100, ...additional,
    MozUserSelect:"none",
    WebkitUserSelect:"none",
    msUserSelect: 'none',
    userSelect: 'none',
     };
  }

  const [{ isMeDragging }, drag] = useDrag(
    () => ({
      type: props.player.playerPosition,
      item: () => {return {id: props.player.id}},
      collect: (monitor: DragSourceMonitor) => ({
        isMeDragging: monitor.isDragging(),
    }),
    end: () => {
      props.setDraggingActive(false);
    }
    
    })
  )

  const [{ isOver, canDrop  }, drop] = useDrop(
    ()=> ({
      accept: props.player.playerPosition,
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop(item: PlayerDto) {
        if (!ref.current) {
          return;
        }
        const dragPlayer = item;
        const dropPlayer = props.player;
        if (dragPlayer.id === dropPlayer.id) { 
          return;
        }props.onSubstitute(item.id, props.player.id)
      }
    }));

  drag(drop(ref));

  const getPlayerStyle = () => {

    if (isMeDragging)
      return { opacity: 0.8, filter: 'drop-shadow(8px 8px 16px #000)' }
    if (canDrop){
      if (isOver)
        return  { opacity: 0.3, filter: 'drop-shadow(15px 15px 16px #FD5)', border: '1px solid #FD5', backgroundColor: 'yellow'}
      else
        return { opacity: 0.8, filter: 'drop-shadow(8px 8px 16px #FFF)', border: '1px dashed #FFF'}
    }
    else
      return { opacity: 1, filter: 'drop-shadow(1px 2px 1px #000)' }
  }

  const getPlayerPoints = () => {
    if (stats === undefined)
      requestPlayerStats(props.player.id, onStatsReceived);
    else
      setOpenStatsDialog(true);
  }

  const onStatsReceived = (s: TotalPlayerStatsDto) => {
    setStats(s);
    setOpenStatsDialog(true);
  }

  const handleStatsClosed = () => {
    setOpenStatsDialog(false);
  }

  const getValueOrPoints = () => {
    let additionalStyle = {marginTop:'7px', backgroundColor:'rgba(0, 0, 0, .7)', width: '53%', textAlign:'center', cursor:'pointer'};
    if (points){

      let pointsStr: string = points.points + " p";
      if (props.isCaptain)
        pointsStr = "2x" + pointsStr;
      if ((points.matchStatus) === EnumMatchStatus.LIVE){
        return <Typography onClick={getPlayerPoints} style={getTypographyStyle(false, {...additionalStyle, ...{color:'limegreen', fontStyle:'oblique'}})}>{pointsStr}</Typography>
      }

      else{
        return <Typography onClick={getPlayerPoints}  style={getTypographyStyle(false, {...additionalStyle, ...{color:'white'}})}>{pointsStr}</Typography>
      }
    }
    return <Typography onClick={getPlayerPoints}  style={getTypographyStyle(false, {...additionalStyle, ...{color:'white'}})}>{props.player.cost +" M"}</Typography>
  }

  return (
      <ThemeProvider theme={theme}>
        <div id={props.player.id + ""} ref={ref} style={getPlayerStyle()} >
          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}
            sx={{
                height: 80,
                width: 80,
            }}>

            <Avatar variant={"rounded"} alt={props.player.name} src={getJerseySrc() } 
                onContextMenu={(e)=>{e.preventDefault()}}
                style={{
                    width: 80,
                    height: 75,
                    position: 'absolute',
                    MozUserSelect:"none",
                    WebkitUserSelect:"none",
                    msUserSelect: 'none',
                    userSelect: 'none',
                }} />

            {props.isCaptain &&
              <Avatar variant={"rounded"} alt={props.player.name} src={captainImg }
              onContextMenu={(e)=>{e.preventDefault()}}
              

                style={{
                    width: 85,
                    height: 85,
                    top: 1,
                    left: 0,
                    MozUserSelect:"none",
                    WebkitUserSelect:"none",
                    msUserSelect: 'none',
                    userSelect: 'none',
                    position: 'absolute',
                }} />
              }

            <Stack sx={{paddingTop:'8px', whiteSpace:'nowrap'}} width={"100%"} justifyContent="center" direction={'column'} alignItems={"center"}>
              <Typography style={getTypographyStyle(true)}>{props.player.name}</Typography>
              {getCountyFlagAvatar(props.player.teamName, {width:'20px', height:'20px', boxShadow: '0 0 5px #9ecaed', MozUserSelect:"none", WebkitUserSelect:"none", msUserSelect: 'none', userSelect: 'none',})}
              {getValueOrPoints()}
            </Stack>
          </Stack>
        </div>
        <StatsDialog open={openStatsDialog} onDialogClosed={handleStatsClosed} stats={stats}/>
      </ThemeProvider>
    );
}