import RoundDto from "../model/event/RoundDto";
import PlayerDto from "../model/player/PlayerDto";
import EnumPlayerPosition from "./enum/EnumPlayerPosition";

enum EnumPositionStatus{
    LOW,
    EXACT,
    EXCEEDED,
}

enum EnumTransferCountStatus{
    OK,
    EXCEEDED,
}

enum EnumBudgetStatus{
    OK,
    EXCEEDED
}

const ValidationTransferRules = {
    GOALKEEPERS: 2,
    DEFENDERS: 5,
    MIDFIELDERS: 5,
    FORWARDS: 3,
    OVERALL: 15
}

interface BudgetStatus {
    spent: number;
    label: string;
    status: EnumBudgetStatus;
}

interface TransferCountStatus {
    allowed: number;
    used: number;
    status: EnumTransferCountStatus;
}

interface PlayersInOut {
    in: PlayerDto[];
    out: PlayerDto[];
}

// const PositionValidityMap: Record <EnumPlayerPosition, number> = {
//     EnumPlayerPosition.GOALKEEPER: 2,
// }

const isGKValid = (gk: number): boolean => {
    return (gk === ValidationTransferRules.GOALKEEPERS);
}

const isDFValid = (df: number): boolean => {
    return (df === ValidationTransferRules.DEFENDERS);
}

const isMFValid = (mf: number): boolean => {
    return (mf === ValidationTransferRules.MIDFIELDERS);
}

const isFWValid = (fw: number): boolean => {
    return (fw === ValidationTransferRules.FORWARDS);
}

const isAllValid = (gk: number, df: number, mf: number, fw: number, all: number): boolean => {
    return (isGKValid(gk) && isDFValid(df) && isMFValid(mf) && isFWValid(fw) && all === ValidationTransferRules.OVERALL);
}

const isAllValidByList = (p: PlayerDto[]): boolean => {
    return (isGKValid(getPlayersOnPosition(p, EnumPlayerPosition.GOALKEEPER).length) && isDFValid(getPlayersOnPosition(p, EnumPlayerPosition.DEFENDER).length)
     && isMFValid(getPlayersOnPosition(p, EnumPlayerPosition.MIDFIELDER).length) && isFWValid(getPlayersOnPosition(p, EnumPlayerPosition.FORWARD).length)
      && p.length === ValidationTransferRules.OVERALL);
}

const getPlayersOnPosition = (players: PlayerDto[], position: EnumPlayerPosition) : PlayerDto[] => {
    let res: PlayerDto[] = players.filter((p: PlayerDto) => { 
        return position === Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(p.playerPosition)]
    }).sort((n1,n2) => players.indexOf(n1) - players.indexOf(n2));
    // if (position === EnumPlayerPosition.DEFENDER)
    //     console.log('getPlayersOnPostion', players);
    return res;
}

const isPositionValid = (players: PlayerDto[], position: EnumPlayerPosition): boolean => {
    switch (position) {
        case EnumPlayerPosition.GOALKEEPER:
            return isGKValid(getPlayersOnPosition(players, position).length);
        case EnumPlayerPosition.DEFENDER:
            return isDFValid(getPlayersOnPosition(players, position).length);
        case EnumPlayerPosition.MIDFIELDER:
            return isMFValid(getPlayersOnPosition(players, position).length);
        case EnumPlayerPosition.FORWARD:
            return isFWValid(getPlayersOnPosition(players, position).length);
        default: return false;
    }
}

const getRequestedNumber = (position: EnumPlayerPosition): number => {
    switch (position) {     
        case EnumPlayerPosition.GOALKEEPER:
            return ValidationTransferRules.GOALKEEPERS;
        case EnumPlayerPosition.DEFENDER:
            return ValidationTransferRules.DEFENDERS;
        case EnumPlayerPosition.MIDFIELDER:
            return ValidationTransferRules.MIDFIELDERS;
        case EnumPlayerPosition.FORWARD:
            return ValidationTransferRules.FORWARDS;
    
        default: return 0;
    }
}

const getPositionStatus = (players: PlayerDto[], position: EnumPlayerPosition): EnumPositionStatus => {
    let diff: number = getPlayersOnPosition(players, position).length - getRequestedNumber(position);

    if (diff < 0) return EnumPositionStatus.LOW;
    if (diff === 0) return EnumPositionStatus.EXACT;
    return EnumPositionStatus.EXCEEDED;    

  }

  
const getPlayersInOut = (players: PlayerDto[], chosenPlayers: PlayerDto[]): PlayersInOut => {
    let playersIn: PlayerDto[] = [];
    chosenPlayers.forEach(p => {
        if (!players.some(player => player.id === p.id))
          playersIn.push(p);
    });

    let playersOut: PlayerDto[] = [];
    players.forEach(p => {
    if (!chosenPlayers.some(player => player.id === p.id))
        playersOut.push(p);
    });

    return {in: playersIn, out: playersOut};
}

const getTransfersCountStatus = (previouslyTransfersUsed: number, players: PlayerDto[], chosenPlayers: PlayerDto[], allowed: number): TransferCountStatus => {

    let count: number = previouslyTransfersUsed;
    if (players.length === 0)
        return {used: count, allowed: -1, status: EnumTransferCountStatus.OK};

    chosenPlayers.forEach(p => {
      if (!players.some(player => player.id === p.id))
        count++;   
    });
    
    if (allowed < 0)
        return {used: count, allowed: -1, status: EnumTransferCountStatus.OK};

    return {used: count, allowed: allowed, status: (count > allowed && allowed >= 0)? EnumTransferCountStatus.EXCEEDED: EnumTransferCountStatus.OK }
  }

const getBudgetStatus = (round: RoundDto, players: PlayerDto[]): BudgetStatus => {


    let spent: number = (players.length <= 0)? 0: players.map(p=>p.cost).reduce((a,c)=>a+c);
    let bs: BudgetStatus = {spent: spent, label: ((spent) + " / " + round.budget), status: ((round.budget - spent) < 0) ? EnumBudgetStatus.EXCEEDED: EnumBudgetStatus.OK};

    return bs;

}

export {
    EnumPositionStatus,
    EnumBudgetStatus,
    EnumTransferCountStatus,
    isGKValid,
    isDFValid,
    isMFValid,
    isFWValid,
    isAllValid,
    isAllValidByList,
    isPositionValid,
    getPositionStatus,
    getPlayersOnPosition,
    getBudgetStatus,
    getTransfersCountStatus,
    getPlayersInOut,

  };
export type {BudgetStatus, TransferCountStatus, PlayersInOut};