import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";
import { EVENT_ID } from "./EventController";

interface TransferControllerType {
  axiosGetAllTeams: any;
  axiosGetNextRound: any;
}

export function TransferController(): TransferControllerType {
  const { authData } = useAppContext();

  const axiosGetAllTeams = () => {
    return axios.get(Endpoints.TEAM + '/getAllTeams', axiosConfig(authData!.token));
  };

  const axiosGetNextRound = () => {
    return axios.get(Endpoints.TEAM + '/' + EVENT_ID+ '/getNextRound', axiosConfig(authData!.token));
  };

  return { axiosGetAllTeams, axiosGetNextRound };
}

export type { TransferControllerType};
