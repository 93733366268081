enum EnumActionType {
    MINS_APPEARANCE = 1,
    MINS_60 = 2,
    GOAL_GK = 3,
    GOAL_DF = 4,
    GOAL_MF = 5,
    GOAL_FW = 6,
    ASSIST = 7,
    CLEAN_GK = 8,
    CLEAN_DF = 9,
    CLEAN_MF = 10,
    SAVES_GK = 11,
    PENALTY_SAVED_GK = 12,
    PENALTY_MISSED = 13,
    PENALTY_SCORED = 14,
    PENALTY_SERIES_MISSED = 15,
    PENALTY_SERIES_SCORED = 16,
    PENALTY_SERIES_SAVED_GK = 17,
    PENALTY_SERIES_CONSEDED_GK = 18,
    CONSEDED_GK = 19,
    CONSEDED_DF = 20,
    CARD_YELLOW = 21,
    CARD_RED = 22,
    OWN_GOAL = 23,
    BONUS_MOM = 24,
    BONUS_2_GOALS = 25,
    BONUS_3_GOALS = 26,
    BONUS_GOAL_ASSIST = 27,
    
}

export default EnumActionType;