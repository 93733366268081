import {useState} from "react";
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ChangablePlayers } from "../../../utils/Formation";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import PlayerDto from "../../../model/player/PlayerDto";
import Chip from '@mui/material/Chip';
import { getCountyFlagAvatar } from "../../../utils/CountryColors";
import SubOutIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import SubInIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import DialogContentText from "@material-ui/core/DialogContentText";

interface SubInOutCardProps{
    title: string;
    subIn: ChangablePlayers | null;
    subOut: ChangablePlayers | null;
    setPropSelectedIn: Function;
    setPropSelectedOut: Function;
}

export default function SubInOutCard(props: SubInOutCardProps) {

    const [ selectedIn, setSelectedIn] = useState<PlayerDto[]>([]);
    const [ selectedOut, setSelectedOut] = useState<PlayerDto[]>([]);
    
    const selectedPlayerIn = (player: PlayerDto)=>{
        let sp: PlayerDto[] = [...selectedIn];
        let ind: number = sp.findIndex(p => p.id === player.id);
        if (ind >= 0)
            sp.splice(ind, 1);
        else (sp.push(player));

        setSelectedIn([...sp]);
        props.setPropSelectedIn([...sp], props.subIn);
    }

    const selectedPlayerOut = (player: PlayerDto)=>{
        let sp: PlayerDto[] = [...selectedOut];
        let ind: number = sp.findIndex(p => p.id === player.id);
        if (ind >= 0)
            sp.splice(ind, 1);
        else (sp.push(player));

        setSelectedOut([...sp]);
        props.setPropSelectedOut([...sp], props.subOut);
    }

    const getPlayerChipIn = (p: PlayerDto) =>{
        return <Chip
                        key={p.id}
                        avatar={getCountyFlagAvatar(p.teamName)}
                        label={p.name + " (" + (p.cost) + " M)"}
                        variant="outlined"
                        onClick={() => selectedPlayerIn(p)}
                        style={{backgroundColor: (selectedIn.indexOf(p) >= 0) ? 'green': 'white'}}
                        
                    />  
    }

    
    const getPlayerChipOut = (p: PlayerDto) =>{
        return <Chip
                        key={p.id}
                        avatar={getCountyFlagAvatar(p.teamName)}
                        label={p.name + " (" + (p.cost) + " M)"}
                        variant="outlined"
                        onClick={() => selectedPlayerOut(p)}
                        style={{backgroundColor: (selectedOut.indexOf(p) >= 0) ? 'red': 'white'}}
                    />  
    }

    const getIcon = () => {
        if (props.subIn && props.subIn.players.length > 0)
            return <SubInIcon sx={{color:"green"}}/>;
        else if (props.subOut && props.subOut.players.length > 0)
            return <SubOutIcon sx={{color:"red"}}/>;
        else 
            return <CheckCircleIcon  sx={{color:"green"}}/>
    }

    return (
    <Card sx={{ minWidth: 275, }}>
        <CardContent>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" sx={{ width:"100%", justifyContent: "center", alignItems: "flex-end"}} spacing={1}>
                    <Typography sx={{ fontSize: 14 }}> 
                        {props.title}
                    </Typography>
                    {getIcon()}
                </Stack>
            
                <Stack>
                    {
                        (props.subIn && props.subIn.players.length > 0 ) && 
                        <Stack direction="column">
                            <Stack sx={{justifyContent: "center", display: "grid"}}>
                                <DialogContentText >
                                    ubacite {props.subIn.count === 1 ? 'jednog': 'dva'} igrača
                                </DialogContentText>
                            </Stack>
                           <Stack direction="row" sx={{display:"flow-root", justifyContent: "center"}}>
                            {
                                props.subIn.players.map((p: PlayerDto) => (
                                    getPlayerChipIn(p)
                                )
                            )}
                            </Stack>
                        </Stack>
                    }

                    {
                        (props.subOut && props.subOut.players.length > 0 ) && 
                        <Stack direction="column">
                            <Stack sx={{justifyContent: "center", display: "grid"}}>
                                <DialogContentText>
                                    izbacite {props.subOut.count === 1 ? 'jednog': 'dva'} igrača
                                </DialogContentText>
                            </Stack>
                            <Stack direction="row" spacing={1} sx={{display:"flow-root", justifyContent: "center"}}>
                            {
                                props.subOut.players.map((p: PlayerDto) => (
                                    getPlayerChipOut(p)
                                )
                            )}
                            </Stack>
                        </Stack>
                        
                    }

                </Stack>
            </Stack>
        </CardContent>
    </Card>
    );
}
    
