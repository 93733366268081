import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from "@mui/material";
import Typography from '@mui/material/Typography';
import { getFormationImg } from "../../../utils/Formation";

export default function Rules() {

    const getTableStyle = () => {
      return `
                  .rules-table {
                    font-family: Arial, Helvetica, sans-serif;
                    border-collapse: collapse;
                    width: 100%;
                    white-space: nowrap;
                    overflow-x: scroll;
                    display: 'flow-root' important!;
                  }
                  
                  .rules-table td, .rules-table th {
                    border: 1px solid #ddd;
                    padding: 8px;
                  }
                  
                  .rules-table tr:nth-child(even){background-color: #f2f2f2;}
                  
                  .rules-table tr:hover {background-color: #ddd;}
                  
                  .rules-table th {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    text-align: left;
                    background-color: #04AA6D;
                    color: white;
                  }
                `
    }

    return(
    <Stack spacing={2}>
        <Typography variant={"h5"} >Pravila</Typography>
        <Typography variant={"body1"}> 
          <br/>
          Menađer je fantasy igra u kojoj se nalazite u ulozi selektora. Ali ne jedne, već svih reprezentacija koje nastupaju na EP '24'. Uživajte prateći prvenstvo, našu reprezentaciju, ali i svoje igrače kojima ste ukazali šansu i koji će vam doneti bodove.
          Igra je veoma jednostavna - odabereš igrače, a igrači svojim učinkom na terenu ti donose bodove. Čiji igrači skupe najviše poena učiniće svog selektora pobednikom ove igre i doneti mu zvanje TOP MENAĐER!

          <p> Ovo je beta verzija igre. Za sve probleme, predloge i sugestije, pišite na: <b>vladimir.jovanovic@aparteko.com</b></p>
          Tokom trajanja igre organizator zadržava pravo izmene cena igrača i budžeta za predstojeće runde.

        </Typography>
        <Stack>
          <Accordion>
            <AccordionSummary sx={{justifyContent:'center', display:'flex', backgroundColor: 'lavender'}}
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>Inicijalni izbor igrača</Typography>
            </AccordionSummary>
            
            <AccordionDetails>
              <p>Pre početka prve runde - <b>petak, 14. jun, 21.00</b>, moraš odabrati svojih 15 igrača. Za prvu rundu imaćeš na raspolaganju <b>135 miliona</b>. Svaki igrač ima svoju cenu i u prvoj rundi možeš imati najviše <b>2 igrača</b> iz iste selekcije.</p>
              Izbor čine:
              <ul>
                <li>2 golmana</li>
                <li>5 odbrambenih</li>
                <li>5 veznih</li>
                <li>3 napadača</li>
              </ul>
              
              <p>Inicijalni izbor se obavlja u sekciji <b>Transferi</b> gde ćeš odmah nakon registracije biti preusmeren.</p>
            </AccordionDetails>
        </Accordion>
        
        <Accordion>
            <AccordionSummary sx={{justifyContent:'center', display:'flex', backgroundColor: 'lavender'}}
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>Startnih 11</Typography>
            </AccordionSummary>
            
            
            <AccordionDetails>
            <p>Izbor od 15 igrača koji čine tvoj tim moraš svesti na 11 koji će u nekom kolu da nastupe. I, naravno, pažljivo odaberi kapitena čiji se poeni dupliraju. Ovaj izbor možeš uraditi u sekciji <b>Moj tim</b>. Moraš prvo odabrati 15 igrača pre nego što u ovu sekciju možeš ući. Rok za postavljanje formacije i odabir igrača iz startnih 11 je isti kao i za transfere - prva utakmica u svakoj rundi.</p> 
            <p>Kao svaki dobar menađer, odabraćeš i najbolju <b>formaciju</b> u skladu sa tvojom fudbalskom filozofijom. U Menađeru imaš na raspolaganju sledeće formacije:</p>
            <Stack direction={"row"} sx={{display: 'flex', flexWrap: 'wrap'}} spacing={5}>
              <Stack direction={"column"}>{getFormationImg('4-4-2')} <div>4-4-2</div></Stack>
              <Stack direction={"column"}>{getFormationImg('4-3-3')} <div>4-3-3</div></Stack>
              <Stack direction={"column"}>{getFormationImg('4-5-1')} <div>4-5-1</div></Stack>
              <Stack direction={"column"}>{getFormationImg('5-4-1')} <div>5-4-1</div></Stack>
              <Stack direction={"column"}>{getFormationImg('5-3-2')} <div>5-3-2</div></Stack>
              <Stack direction={"column"}>{getFormationImg('3-5-2')} <div>3-5-2</div></Stack>
              <Stack direction={"column"}>{getFormationImg('3-4-3')} <div>3-4-3</div></Stack>
            </Stack>
              
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary sx={{justifyContent:'center', display:'flex', backgroundColor: 'lavender'}}
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>Transferi</Typography>
            </AccordionSummary>
            
            
            <AccordionDetails>
              Transferi se moraju obaviti pre početka runde u sekciji <b>Transferi</b>. Za razliku od nekih drugih fantasy-ja, možete obaviti transfere i neposredno pred početak kola. Ali ne čekajte poslednji sekund! Nećete stići da obavite taktičke promene.
              <p>U tabeli ispod nalaze se pravila svake runde:</p>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <style>{getTableStyle()}</style>
                <table className='rules-table' style={{display:'flow-root'}}>
                  <tr style={{backgroundColor:'#2257BF', color:'white'}}>
                    <td>redni broj</td>
                    <td>naziv</td>
                    <td>budžet</td>
                    <td>rok</td>
                    <td>igrača iz tima</td>
                    <td>dozvoljenih transfera</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Grupa - 1. kolo</td>
                    <td>150</td>
                    <td>14.06. 21:00</td>
                    <td>2</td>
                    <td>∞</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Grupa - 2. kolo</td>
                    <td>150</td>
                    <td>19.06. 15:00</td>
                    <td>2</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Grupa - 3. kolo</td>
                    <td>150</td>
                    <td>23.06. 21:00</td>
                    <td>2</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Osmina finala</td>
                    <td>155</td>
                    <td>29.06. 18:00</td>
                    <td>3</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Četvrtfinale</td>
                    <td>158</td>
                    <td>05.07. 18:00</td>
                    <td>4</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Polufinale</td>
                    <td>160</td>
                    <td>09.07. 21:00</td>
                    <td>6</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Finale</td>
                    <td>160</td>
                    <td>14.07. 21:00</td>
                    <td>8</td>
                    <td>2</td>
                  </tr>
                </table>
              </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary sx={{justifyContent:'center', display:'flex', backgroundColor: 'lavender'}}
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>Sistem dodele poena</Typography>
            </AccordionSummary>
                        
            <AccordionDetails>
                  <p>Igrači donose poene prema sledećem ključu:</p>
                  <div style={{display: 'flex', flexDirection: 'row'}}> 
                    <style>{getTableStyle()}</style>
                    <table className='rules-table'style={{display:'flow-root'}}><tbody><tr style={{backgroundColor:'#2257BF', color:'white'}}><td>Učinak igrača</td><td>poeni</td></tr><tr><td>Nastup na meču</td><td>1</td></tr><tr><td>60+ minuta</td><td>2</td></tr><tr><td>Gol (golman)</td><td>6</td></tr><tr><td>Gol (odbrana)</td><td>6</td></tr><tr><td>Gol (vezni)</td><td>5</td></tr><tr><td>Gol (napad)</td><td>4</td></tr><tr><td>Asistencija</td><td>3</td></tr><tr><td>Tim nije primio gol (golman)</td><td>4</td></tr><tr><td>Tim nije primio gol (odbrana)</td><td>4</td></tr><tr><td>Tim nije primio gol (vezni)</td><td>1</td></tr><tr><td>Odbrane</td><td>1</td></tr><tr><td>Odbranjeni penal</td><td>4</td></tr><tr><td>Promašen penal</td><td>-2</td></tr><tr><td>Gol iz penala</td><td>3</td></tr><tr><td>Promašen penal (penal serija)</td><td>-4</td></tr><tr><td>Gol iz penala (penal serija)</td><td>2</td></tr><tr><td>Odbranjeni penal (penal serija)</td><td>3</td></tr><tr><td>Gol iz penala (penal serija)</td><td>-1</td></tr><tr><td>Primljen gol (golman)</td><td>-1</td></tr><tr><td>Primljen gol (odbrana)</td><td>-1</td></tr><tr><td>Žuti karton</td><td>-1</td></tr><tr><td>Crveni karton</td><td>-3</td></tr><tr><td>Auto gol</td><td>-2</td></tr><tr><td>Igrač utakmice</td><td>3</td></tr><tr><td>Bonus - 2 data gola</td><td>1</td></tr><tr><td>Bonus - het-trik</td><td>3</td></tr><tr><td>Bonus - gol + asistencija</td><td>2</td></tr></tbody></table>
                  </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary sx={{justifyContent:'center', display:'flex', backgroundColor: 'lavender'}}
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>Lige</Typography>
            </AccordionSummary>
            
            
            <AccordionDetails>
                Postoji globalna liga u kojoj svi menađeri učestvuju u borbi za zvanje TOP MENAĐER.
                Pored globalne, možete se prijaviti za neograničeni broj privatnih liga. Potrebno je samo da unesete kod koji vam je poslao neko od prijatelja.
                <p>Ali ne čekaj da te pozovu - kreiraj i sam ligu i podeli pristupni kod sa prijateljima. Svako može kreirati do 5 liga.</p>
                U svim ligama postoju ukupna lista, ali i lista za svaku rundu. Na vama je da se dogovorite ko koga vodi na pivo.
              
            </AccordionDetails>
        </Accordion>
      </Stack>

    </Stack>)
}