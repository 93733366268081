import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";

interface EventControllerType {
  axiosGetNextRound: any;
  axiosGetAllRounds: any;
  axiosGetAllRoundsShort: any;
  axiosGetMatchesForRound: any;
}

export const EVENT_ID: number = 1;

export function EventController(): EventControllerType {
  const { authData } = useAppContext();

  const axiosGetNextRound = () => {
    return axios.get(Endpoints.EVENT + '/' + EVENT_ID +'/get-next-round', axiosConfig(authData!.token));
  };

  const axiosGetAllRounds = () => {
    return axios.get(Endpoints.EVENT + '/' + EVENT_ID +'/get-all-rounds', axiosConfig(authData!.token));
  };

  const axiosGetAllRoundsShort = () => {
    return axios.get(Endpoints.EVENT + '/' + EVENT_ID +'/get-all-rounds-short', axiosConfig(authData!.token));
  };

  const axiosGetMatchesForRound = (roundId: number) => {
    return axios.get(`${Endpoints.EVENT}/${EVENT_ID}/${roundId}/get-matches-for-round`, axiosConfig(authData!.token));    
  };

  return { axiosGetNextRound, axiosGetAllRounds, axiosGetAllRoundsShort, axiosGetMatchesForRound };
}

export type { EventControllerType};
