import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import {useState} from "react";
import MatchDto from '../../../model/match/MatchDto';
import EnumMatchStatus from '../../../utils/enum/EnumMatchStatus';
import { getCountryName12chr, getCountyFlagAvatar } from '../../../utils/CountryColors';
import { match } from 'assert';
import EnumMatchPeriod from '../../../utils/enum/EnumMatchPeriod';
import { getDateAsString, getTimeAsString, useEffectOnce } from '../../../utils/Utils';
import PlayerPointsService, { PlayerPointsServiceType } from '../../../service/playerpoints/PlayerPointsService';
import PlayerActionDto from '../../../model/playerpoints/PlayerActionDto';
import MatchActionsPerPlayerDto from '../../../model/playerpoints/MatchActionsPerPlayerDto';
import MatchPlayerStatsDialog from '../team/player/MatchPlayersStatsDialog';


interface MatchCardProps{
    match: MatchDto;
}

export default function MatchCard(props: MatchCardProps) {  
  
  const { getMatchActionsPerPlayer} : PlayerPointsServiceType = PlayerPointsService();
  const [ startDate] = useState<Date>(new Date (props.match.startDate));
  const [isHovered, setIsHovered] = useState(false);
  const [openMatchPlayersStatsDialog, setOpenMatchPlayersStatsDialog] = useState<boolean>(false);
  const [matchStats, setMatchStats] = useState<MatchActionsPerPlayerDto | null>(null);

  const isLive = (): boolean => {
    return (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.LIVE)]) 
  }

  const isNotStarted = (): boolean => {
    return (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.NOT_STARTED)]) 
  }

  const getStartTime = (): string => {

    if (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.NOT_STARTED)])
      return 'završena';
    else if (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.LIVE)])
      return 'u toku';

    return getTimeAsString(startDate);
    // return  startDate.getDate() + "."+ (startDate.getMonth()+1) + "." + " " + startDate.getHours() + ":" + startDate.getMinutes();
  }

  const getPeriod = (): string => {
    return Object.values(EnumMatchPeriod)[Object.keys(EnumMatchPeriod).indexOf(props.match.matchPeriod)];
  }

  const getStyle = () => {

    let common = {display: 'inline-table', padding:'5px', textAlign:'center'};
    let specific = {}

    if (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.NOT_STARTED)])
      specific = {backgroundColor: 'lavender'};
    else if (props.match.matchStatus + "" === Object.keys(EnumMatchStatus)[Object.values(EnumMatchStatus).indexOf(EnumMatchStatus.LIVE)])
      specific = {backgroundColor: 'darkseagreen', color: 'white', fontWeight: 'bolder'};
    else
      specific = {backgroundColor: 'forestgreen', color: 'white'};
    return {...common, ...specific};
    // return {};
  }

  const handleHoverStart = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };

  const stackResultStyles = {
    cursor: 'pointer',
    '& .hover-underline': {
      textDecoration: 'underline',
      color: isHovered ? 'rgba(255, 255, 255, 0.7)' : 'inherit',
    },
  };

  const onStatsReceived = (s: MatchActionsPerPlayerDto) => {
    setMatchStats(s);
    setOpenMatchPlayersStatsDialog(true);
  }

  const handleStatsClosed = () => {
    setOpenMatchPlayersStatsDialog(false);
  }

  const showMatchStats = () => {
    getMatchActionsPerPlayer(props.match.id, onStatsReceived);
  }

  return (
  <Card sx={getStyle()}>
    <Stack direction={"row"}>
      <Stack direction={"column"}>
        <Stack direction={"row"} spacing={2} sx={{alignItems:"center", justifyContent:"space-between"}}>
          <Stack direction={"row"} spacing={1} sx={{alignItems:"center"}}>
            {getCountyFlagAvatar(props.match.host.name, {width:'25px', height:'25px'})}
            <Typography variant='subtitle1' noWrap>
              {getCountryName12chr(props.match.host.name)}
            </Typography>
          </Stack>
          <Stack        
            sx={stackResultStyles}
            
              onClick={()=>showMatchStats()}
            
              onMouseEnter={handleHoverStart}
              onMouseLeave={handleHoverEnd}
              onTouchStart={handleHoverStart}
              onTouchEnd={handleHoverEnd}
            >
            <Typography variant='h6' noWrap className="hover-underline">
              {isNotStarted()?"":props.match.homeScore}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={"row"}  spacing={5} sx={{alignItems:"center", justifyContent:"space-between"}}>
          <Stack direction={"row"} spacing={1} sx={{alignItems:"center"}} >
          {getCountyFlagAvatar(props.match.visitor.name, {width:'25px', height:'25px'})}
            
            <Typography variant='subtitle1' noWrap>
              {getCountryName12chr(props.match.visitor.name)}
            </Typography>
          </Stack>

          <Stack        
              sx={stackResultStyles}
              onClick={()=>showMatchStats()}

              onMouseEnter={handleHoverStart}
              onMouseLeave={handleHoverEnd}
              onTouchStart={handleHoverStart}
              onTouchEnd={handleHoverEnd}
            >
            <Typography variant='h6' noWrap className="hover-underline">
                {isNotStarted()?"":props.match.visitorsScore}
              </Typography>
          </Stack>
        </Stack>
      </Stack>
      {
        isLive() && 
        <Stack spacing={1} direction={"row"} sx={{padding:'8px', justifyContent:'center'}}>
          <Divider orientation="vertical" sx={{bgcolor:'white'}} flexItem />
          <Stack direction={"column"} sx={{justifyContent:'center'}}>
            {/* <Typography>
              {startDate.getHours() + ":" + startDate.getMinutes()}
            </Typography>  */}
            <Typography>
              {getPeriod()}
            </Typography>
          </Stack>
        </Stack>
      }
            {
        isNotStarted() && 
        <Stack spacing={1} direction={"row"} sx={{padding:'8px', justifyContent:'center'}}>
          <Divider orientation="vertical" sx={{bgcolor:'darkseagreen'}} flexItem />
          <Stack direction={"column"}>
            <Typography>
              {getDateAsString(startDate)}
            </Typography>
            <Typography>
              {getTimeAsString(startDate)}
            </Typography>
          </Stack>
        </Stack>
      }
    </Stack>
    <MatchPlayerStatsDialog open={openMatchPlayersStatsDialog} onDialogClosed={handleStatsClosed} stats={matchStats}/>
  </Card>
  );
}