import { Route, Routes } from "react-router-dom";
import Registered from "./public/Registered";
// import Login from "../components/public/login/Login"
// import * as React from 'react';
import SignIn from "./public/SignIn";
import SignUp from "./public/SignUp";

export default function PublicRoute() {
  return (
    <Routes>
      <Route path="/" element={<SignIn/>} />
      <Route path="/register" element={<SignUp/>} />
      <Route path="/registered" element={<Registered/>} />
    </Routes>
  );
}