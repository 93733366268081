import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { propsToClassKey } from '@mui/styles';

interface AreYouSureDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (id: number) => void;
  roundToChange: number;
}

export const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({ open, onClose, onConfirm, roundToChange }) => {


const executeConfirm = () =>{
    onConfirm(roundToChange);
}

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Da li si siguran?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Da li si siguran da želiš da promeniš status runde?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Odustani
        </Button>
        <Button onClick={ executeConfirm} color="primary" >
          Promeni status runde
        </Button>
      </DialogActions>
    </Dialog>
  );
};