import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AuthContext";
import { UserController, UserControllerType } from "../../controller/user/UserController";
import UserReadDto from "../../model/user/UserDto";
import { handleErrorMessage } from "../../utils/Utils";

interface UserServiceType {
  logout: any;
  getUser: any;
  user: any;
}

export default function UserService(): UserServiceType {
  const { showMessage, authData, deleteAuthData, updateAuthData } = useAppContext();

  const { axiosLogout, axiosGetUser }: UserControllerType = UserController();

  const navigate = useNavigate();

  const [user, setUser] = useState<UserReadDto>();

  const logout = () => {
    console.log('logout in userService');   
    deleteAuthData();
    // navigate("/");
    // axiosLogout()
    //   .then((result: any) => {
    //     deleteAuthData();
    //     navigate("/");
    //   })
    //   .catch((error: any) => {
    //     handleErrorMessage(showMessage, error);
    //   });
  };

  const getUser = () => {
    axiosGetUser()
      .then((result: any) => {
        setUser(result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      });
  };

  return { logout, getUser, user };
}

export type { UserServiceType };
