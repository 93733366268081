import { useState } from 'react';
import Stack from '@mui/material/Stack';
import MatchDto from "../../model/match/MatchDto";
import PlayerDto from "../../model/player/PlayerDto";
import { useEffectOnce } from "../../utils/Utils";
import EnumPlayerPosition from '../../utils/enum/EnumPlayerPosition';
import { actions, ActionType, getAction } from '../../utils/ActionType';
import AdminPlayer from './AdminPlayer';
import CreatePlayerActionDto from '../../model/playerpoints/CreatePlayerActionDto';
import PlayerActionDto from '../../model/playerpoints/PlayerActionDto';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Typography from '@mui/material/Typography';
import { sortPositions } from '../../utils/Formation';

interface AdminPlayersProps{
    match: MatchDto;
    getSportTeamPlayers: Function;
    sendPlayerAction: Function;
    getAllPlayerActions: Function;
    removeAction: Function;
}

export default function AdminPlayers(props: AdminPlayersProps) {
    // const { getSportTeamPlayers} : AdminServiceType = AdminService();

    const [homePlayers, setHomePlayers] = useState<PlayerDto[]>([]);
    const [visitorsPlayers, setVisitorsPlayers] = useState<PlayerDto[]>([]);
    const [playerActions, setPlayerActions] = useState<PlayerActionDto[]>([]);

    const onHomePlayersRetreived = (p: PlayerDto[]) => {
        setHomePlayers(p);
    }
    
    const onVisitorsPlayersRetreived = (p: PlayerDto[]) => {
        setVisitorsPlayers(p);
    }

    const onGetAllPlayerActionRetreived = (pas: PlayerActionDto[]) =>{
        setPlayerActions(pas.sort((a, b) => b.id - a.id));
    }

    const sendPlayerAction = (action: ActionType, player: PlayerDto ) => {
        let dto: CreatePlayerActionDto = {actionType: actions.indexOf(action), playerId: player.id, matchId: props.match.id};
        props.sendPlayerAction(dto);
    }

    useEffectOnce(() => {
        props.getSportTeamPlayers(props.match.host.id, onHomePlayersRetreived);
        props.getSportTeamPlayers(props.match.visitor.id, onVisitorsPlayersRetreived);

        props.getAllPlayerActions(props.match.id, onGetAllPlayerActionRetreived);
    });

    return (
        <Stack direction={"row"}>
            <Stack sx={{display:'flex', width:'35%'}}>
            {
                homePlayers.sort(sortPositions).map((p) => (
                    // getPlayer(p)
                    <AdminPlayer player={p} onSendAction={sendPlayerAction}/>
                ))
            }
            </Stack>
            <Stack sx={{display:'flex', width:'35%'}}>
            {
                visitorsPlayers.sort(sortPositions).map((p) => (
                    <AdminPlayer player={p} onSendAction={sendPlayerAction}/>
                ))
            }
            </Stack>
            <Stack sx={{display:'flex', width:'30%'}}>
            {
                playerActions.map((pa: PlayerActionDto) =>(
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack direction={'row'}>
                            <RemoveCircle onClick={() => props.removeAction(pa.id)}/>
                            <Typography>{pa.player.name}</Typography>
                        </Stack>
                        <Typography>{getAction(pa.actionType).name}</Typography>
                    </Stack>
                ))
            }
            </Stack>
        </Stack>
    )
}