import React from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {useState} from 'react';

interface JoinLeagueDialogProps{
    onDialogClosed: Function;
    onJoinLeague: Function;
    open: boolean;
}

export default function JoinLeagueDialog(props: JoinLeagueDialogProps) {

    const [code, setCode] = useState<string>('');

    const handleClose = () => {
        props.onDialogClosed(true);
    };

    
    const handleJoin = () => {
        props.onJoinLeague(code);
        props.onDialogClosed(true);
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }

return (
    <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Pridruži se ligi!</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Unesi kod lige:
        </DialogContentText>
        <TextField id="standard-basic" 
            label="kod" 
            variant="standard"
            size="small"
            value={code} onChange={handleCodeChange}
            />
        </DialogContent>
        <DialogActions>

        <Button onClick={handleJoin}
                color="primary"
                disabled={code.length !== 6}
                variant={"contained"}>
            Pridruži se
        </Button>
        <Button onClick={handleClose}
                color="secondary">
            Odustani
        </Button>
        </DialogActions>
    </Dialog>    
);
}
