import * as React from 'react';
import Stack from '@mui/material/Stack';
import PlayerDto from '../../../model/player/PlayerDto';
import CircularStatic from './TransferProgress';
import { Button, Chip, createTheme, ThemeProvider } from '@material-ui/core';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { getBudgetStatus, BudgetStatus, isAllValidByList, EnumBudgetStatus, EnumTransferCountStatus, TransferCountStatus, getTransfersCountStatus, PlayersInOut, getPlayersInOut } from '../../../utils/TransferValidation';
import RoundDto from '../../../model/event/RoundDto';
import { getFormattedDate } from '../../../utils/Utils';
import TransferDialog from './TransferDialog';
import ForwardIcon from '@mui/icons-material/Forward';
import { getCountyFlagAvatar } from '../../../utils/CountryColors';
import ConfirmTransferDialog from './ConfirmTransferDialog';
import { useMediaQuery } from 'react-responsive';

interface TransferStatusProps{
    previousSquad: PlayerDto[];
    chosenPlayers: PlayerDto[];
    nextRound: RoundDto;
    sendRoundSquad: Function;
    transfersUsed: number;
}

const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
    },
  });

export default function TransferStatus(props: TransferStatusProps) {
  
  const [dialogInfo, setDialogInfo] = React.useState<{title: string, text: string, open: boolean, additional: any}>({title: '', text: '', open: false, additional: null});
  const [confirmDialogInfo, setConfirmDialogInfo] = React.useState<{title: string, text: string, open: boolean, additional: any}>({title: '', text: '', open: false, additional: null});
  
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isSmallMobile = useMediaQuery({ query: `(max-width: 550px)` });

  const isValid = (): boolean => { 
        return isAllValidByList(props.chosenPlayers);
    }
  
  const getDeadline = () => {
    return <section style={{textAlign:'center'}}>
        <div ><b>{props.nextRound.name}</b></div>
        <div style={{display:'flex', flexWrap: 'wrap'}}>{!isSmallMobile? 'ROK:':''} {getFormattedDate(props.nextRound.startDate)}</div>
      </section>
  }

  const getBudgetInfo = () => {
    console.log(props.nextRound, props.chosenPlayers);
    let budgetStatus: BudgetStatus = getBudgetStatus(props.nextRound, props.chosenPlayers);
    return <Chip style={{backgroundColor: budgetStatus.status===EnumBudgetStatus.OK? 'limegreen': 'tomato'}}
        label={
        <section style={{textAlign:'center', color:'white'}}>
          <div><b>budžet</b></div>
          <div>{budgetStatus.label}</div>
        </section>
      }></Chip>;
  }

  const getTransfersLeft = () => {
    let transfersCountStatus: TransferCountStatus = getTransfersCountStatus(props.transfersUsed, props.previousSquad, props.chosenPlayers, props.nextRound.transfersAllowed);
    return <Chip style={{backgroundColor: transfersCountStatus.status===EnumTransferCountStatus.OK? 'limegreen': 'tomato'}}
        label={
        <section style={{textAlign:'center', color:'white'}}>
          <div ><b>transferi</b></div>
          <div>{transfersCountStatus.used + " od " + ((transfersCountStatus.allowed <0)? '∞':transfersCountStatus.allowed)}</div>
        </section>
      }></Chip>;
  }

  const handleSetRoundSquad = () => {
    let budgetStatus: BudgetStatus = getBudgetStatus(props.nextRound, props.chosenPlayers);
    if (budgetStatus.status === EnumBudgetStatus.EXCEEDED){
      setDialogInfo({title: 'Prekoračen budžet!', text: `Morate uskladiti sastav tima (${budgetStatus.spent} M) sa raspoloživim budžetom (${props.nextRound.budget} M).`, open: true, additional: null});
      return;
    }

    let transfersCountStatus: TransferCountStatus = getTransfersCountStatus(props.transfersUsed, props.previousSquad, props.chosenPlayers, props.nextRound.transfersAllowed);
    let playersInOut: PlayersInOut = getPlayersInOut(props.previousSquad, props.chosenPlayers);
    if (transfersCountStatus.status === EnumTransferCountStatus.EXCEEDED){
      setDialogInfo({title: 'Previše transfera!', text: `Morate uskladiti broj transfera (${transfersCountStatus.used}) sa dozvoljenim brojem za ovo kolo (${transfersCountStatus.allowed})`, open: true, additional: getCurrentTransfers(playersInOut)});
      return;
    }
    confirmRoundSquad(transfersCountStatus);
  }
  
  const confirmRoundSquad = (transfersCountStatus: TransferCountStatus) => {
    let playersInOut: PlayersInOut = getPlayersInOut(props.previousSquad, props.chosenPlayers);
    setConfirmDialogInfo({title: 'Potvrdite transfere!', text: (playersInOut.out.length > 0) ? `Iskoristile ste ${transfersCountStatus.used} od mogućih ${transfersCountStatus.allowed} transfera`:'Potvrdrite svoj izbor!', open: true, additional:getCurrentTransfers(playersInOut)});
  }

  const getCurrentTransfers = (playersInOut: PlayersInOut) => {

    return <Stack direction={"row"} justifyContent="center" spacing={3}>
              <Stack direction={"column"} spacing={1} justifyContent="center">
                {playersInOut.in.map((p: PlayerDto) => 
                  <Chip key={p.id} 
                    avatar={getCountyFlagAvatar(p.teamName, {boxShadow: '0 0 2px black'})}
                    label={p.name + " (" + (p.cost) + " M)"}
                    variant="outlined"
                    style={{justifyContent:"left"}}
                  />
                )}
              </Stack>
              {
                playersInOut.out.length > 0 &&
                <Stack direction={"column"} justifyContent={"center"} >
                <ForwardIcon htmlColor='green' fontSize='large' />
                <ForwardIcon htmlColor='red' fontSize='large' style={{ rotate:'180deg' }}/>
              </Stack>
              }
              <Stack direction={"column"} spacing={1} justifyContent="center">
              {playersInOut.out.map((p: PlayerDto) => 
                  <Chip key={p.id} 
                    avatar={getCountyFlagAvatar(p.teamName, {boxShadow: '0 0 2px black'})}
                    label={p.name + " (" + (p.cost) + " M)"}
                    variant="outlined"
                    style={{justifyContent:"left"}}
                  />
                )}
              </Stack>
          </Stack>
  } 

  const handleDialogClosed = () => {
    setDialogInfo({title: '', text: '', open: false, additional: null});
  }

  const handleConfirmDialogClosed = () => {
    setConfirmDialogInfo({title: '', text: '', open: false, additional: null});
  }

  const handleConfirmDialogConfirmed = () => {
    setConfirmDialogInfo({title: '', text: '', open: false, additional: null});
    props.sendRoundSquad();
  } 

  return (
    <>
        <ThemeProvider theme={theme}>
            <Stack direction={'row'} justifyContent="space-between" sx={{paddingTop:'20px'}}>
                {/* <Typography>{"Runda: " + props.nextRound.ord}</Typography> */}
                <Stack  direction={"row"} spacing={1} justifyContent="center" sx={{display:'flex', flexWrap: 'wrap', gap:'2px'}}>
                  <Stack direction={"row"} spacing={1} justifyContent="center">
                    {getBudgetInfo()}
                    {props.previousSquad.length >0 && getTransfersLeft()}
                  </Stack>
                  <Chip label={getDeadline()} color="secondary"/>
                </Stack>
                
                <Stack direction="row" alignItems={'center'} justifyContent="center" spacing={2}>
                    <CircularStatic value={props.chosenPlayers.length} maxPlayers={15}/>
                    <Button disabled={!isValid()} variant="contained" color="primary" endIcon={<DoneAllIcon sx={{display:isSmallMobile?'none':'auto'}}/>} onClick={handleSetRoundSquad}>
                        Potvrdi
                    </Button>
                </Stack>
            </Stack>
            <TransferDialog onDialogClosed={handleDialogClosed} info={dialogInfo}/>
            <ConfirmTransferDialog onDialogClosed={handleConfirmDialogClosed} onDialogConfirmed={handleConfirmDialogConfirmed}  info={confirmDialogInfo}/>
        </ThemeProvider>
    </>
  );
}
