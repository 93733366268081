import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TotalPlayerStatsDto from "../../../../model/playerpoints/TotalPlayerStatsDto";
import { getCountyFlagAvatar } from "../../../../utils/CountryColors";
import EnumPlayerPosition from "../../../../utils/enum/EnumPlayerPosition";
import StatsAccordion from "./StatsAccordion";

interface StatsDialogProps{
    onDialogClosed: Function;
    open: boolean;
    stats: TotalPlayerStatsDto | undefined;
}

export default function StatsDialog(props: StatsDialogProps) {

    const handleClose = () => {
        props.onDialogClosed(true);
    };

    const getTotalPoints = () => {
        if ((!props.stats) || (!props.stats.live && (!props.stats.finished || props.stats.finished.length === 0)))
            return <Typography>Nema još odigranih utakmica.</Typography>;

        let total: number = 0;
        if (props.stats.finished)
            total = props.stats.finished.reduce((sum, current) => sum + current.points, 0);

        if (props.stats.live)
            return <Typography variant="body2" sx={{color:'forestgreen'}}>poeni: <b>{total + props.stats.live.points + " (" + props.stats.live.points + " poena uživo)"}</b></Typography> 

        return <Typography variant="body2">poeni: <b>{total}</b></Typography>
    }

    if (props.stats === undefined)
        return (<Dialog open={props.open} onClose={handleClose}>
            <DialogContent>
            <DialogContentText>
                učitavanje...
            </DialogContentText>
            </DialogContent>
        </Dialog>);
    
    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    <Stack direction="row" alignItems={"center"} justifyContent={"center"} spacing={2} sx={{paddingBottom:'20px'}}>
                        <Typography variant="h4">{props.stats.player.name}</Typography>
                        
                        {getCountyFlagAvatar(props.stats.player.teamName, {maxWidth:'30px', maxHeight:'30px', boxShadow: '0 0 5px #9ecaed'})}
                    </Stack>
                    <Typography variant="body2">pozicija: <b>{Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf( props.stats.player.playerPosition)]}</b></Typography>
                    <Typography variant="body2">cena: <b>{props.stats.player.cost + " M"}</b></Typography>
                    {getTotalPoints()}
                </DialogContentText>
                <Stack direction='column'>
                {
                    props.stats.live && <StatsAccordion match={props.stats.live.match} points={props.stats.live.points} live={true} stats={props.stats.live.detailed}/>
                }
                {
                    props.stats.finished && props.stats.finished.map( (f) => (
                        <StatsAccordion match={f.match} live={false} stats={f.detailed} points={f.points} />
                    ))
                }
                </Stack>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleClose}
                        color="secondary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>    
    );
}
