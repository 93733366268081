import React from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {useState} from 'react';

interface CreateLeagueDialogProps{
    onDialogClosed: Function;
    onCreateLeague: Function;
    open: boolean;
}

export default function CreateLeagueDialog(props: CreateLeagueDialogProps) {

    const [name, setName] = useState<string>('');
    const [desc, setDesc] = useState<string>('');

    const handleClose = () => {
        props.onDialogClosed(true);
    };
    
    const handleCreate = () => {
        props.onCreateLeague(name, desc);
        props.onDialogClosed(true);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDesc(e.target.value);
    }

return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Kreiraj novu ligu!</DialogTitle>
            <DialogContent>
            <DialogContentText>            
                Ispod unesi podatke, a zatim ćeš dobiti kod koji treba da podeliš prijateljima.
            </DialogContentText>
            <TextField id="league-name"  style={{paddingBottom:'25px'}}
                required
                label="naziv" 
                variant="standard"
                size="small"
                helperText="Maksimalno 25 karaktera"
                inputProps={{ minLength:3, maxLength: 25 }}
                value={name} onChange={handleNameChange}
                />
            <TextField id="league-desc" 
                label="opis"
                variant="standard"
                multiline
                fullWidth
                size="small"
                helperText="Maksimalno 250 karaktera"
                inputProps={{ maxLength: 250 }}
                value={desc} onChange={handleDescChange}
                />
            </DialogContent>
            <DialogActions>

            <Button onClick={handleCreate}
                    color="primary"
                    variant={"contained"}
                    disabled={name.length < 3}
                    >
                Kreiraj
            </Button>
            <Button onClick={handleClose}
                    color="secondary">
                Odustani
            </Button>
            </DialogActions>
        </Dialog>
    );
}
