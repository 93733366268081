import EnumActionType from "./enum/EnumActionType";

class ActionType {
    public id: number;
    public name: string;
    public points: number;

    public constructor (i: number, n: string, p: number) {
        this.id = i;
        this.name = n;
        this.points = p;
    }
};

const actions: ActionType[] = [
    new ActionType(1,'Nastup na meču',1),
    new ActionType(2,'60+ minuta',2),
    new ActionType(3,'Gol (golman)',6),
    new ActionType(4,'Gol (odbrana)',6),
    new ActionType(5,'Gol (vezni)',5),
    new ActionType(6,'Gol (napad)',4),
    new ActionType(7,'Asistencija',3),
    new ActionType(8,'Tim nije primio gol (golman)',4),
    new ActionType(9,'Tim nije primio gol (odbrana)',4),
    new ActionType(10,'Tim nije primio gol (vezni)',1),
    new ActionType(11,'Odbrane',1),
    new ActionType(12,'Odbranjeni penal',4),
    new ActionType(13,'Promašen penal',-2),
    new ActionType(14,'Gol iz penala',3),
    new ActionType(15,'Promašen penal (penal serija)',-4),
    new ActionType(16,'Gol iz penala (penal serija)',2),
    new ActionType(17,'Odbranjeni penal (penal serija)',3),
    new ActionType(18,'Primljen gol iz penala (penal serija)',-1),
    new ActionType(19,'Primljen gol (golman)',-1),
    new ActionType(20,'Primljen gol (odbrana)',-1),
    new ActionType(21,'Žuti karton',-1),
    new ActionType(22,'Crveni karton',-3),
    new ActionType(23,'Auto gol',-2),
    new ActionType(24,'Igrač utakmice',3),
    new ActionType(25,'Bonus - 2 data gola',1),
    new ActionType(26,'Bonus - het-trik',3),
    new ActionType(27,'Bonus - gol + asistencija',2),
    new ActionType(1001,'Nepoznati događaj',0),

];

const getAction = (eat: EnumActionType) => {
    let at: ActionType[] = actions.filter(a => a.id === Object.values(EnumActionType).indexOf(eat) + 1);

    if (at.length > 0)
        return at[0];
    else return actions[1001];
}

export {actions, getAction};
export type {ActionType};

