import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { AppContext } from "../context/AuthContext";
import { UserController, UserControllerType } from "../controller/user/UserController";
import { ConfirmProvider } from "material-ui-confirm";
import AuthData from "../model/AuthData";
import { handleErrorMessage } from "./Utils";
import { useNavigate } from "react-router";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: "#ffd0db",
    },
  },
});

export default function Store(props: any) {
  const [authData, setAuthData] = useState(localStorage.getItem("fantasy-token") || "");
  const [blockUI, setBlockUI] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { axiosGetUser }: UserControllerType = UserController();
  const navigate = useNavigate();

  const showMessage = (text: any, type: any) => {
    enqueueSnackbar(text, {
      preventDuplicate: true,
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  useEffect(() => {

    const loggedInUserData = authData ? (JSON.parse(authData) as AuthData) : null;

    if (loggedInUserData) {
      // if (!loggedInUserData.token)
      // {
      //   navigate('/registered');
      //   return;
      // }

      console.log(loggedInUserData);
      axiosGetUser(loggedInUserData.token)
        .then((res: any) => {
          const data: any = {
            token: loggedInUserData.token,
            user: res.data,
          };
          updateAuthData(data);
        })
        .catch((error: any) => {
          handleErrorMessage(showMessage, error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAuthData = (data: string) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem("fantasy-token", jsonData);
    setAuthData(jsonData);
  };

  const deleteAuthData = () => {
    setAuthData("");
    localStorage.removeItem("fantasy-token");
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <AppContext.Provider
          value={{
            authData: authData && authData !== "" ? (JSON.parse(authData) as AuthData) : undefined,
            updateAuthData,
            deleteAuthData,
            showMessage,
            blockUI,
            setBlockUI,
            // t: (scope: any, options: any) => i18n.t(scope, { locale, ...options }),
            // locale: locale,
            // setLocale,

            // typeList,
          }}
        >
          {props.children}
        </AppContext.Provider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
