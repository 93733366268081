
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Stack from '@mui/material/Stack';
import MatchDto from '../../../../model/match/MatchDto';
import { getCountyFlagAvatar } from '../../../../utils/CountryColors';
import PlayerMatchPointsDto from '../../../../model/playerpoints/PlayerMatchPointsDto';
import { getAction } from '../../../../utils/ActionType';
import Divider from '@mui/material/Divider';
import { SxProps, Theme } from '@mui/system';
import EnumPlayerPosition from '../../../../utils/enum/EnumPlayerPosition';

interface MatchPlayerStatsAccordionProps{
  match: MatchDto;
  live: boolean;
  stats: PlayerMatchPointsDto;
  style?: SxProps<Theme>;
}


export default function MatchPlayerStatsAccordion(props: MatchPlayerStatsAccordionProps) {

  const getStyle = () => {
    return {color: props.live?'white':'grey'}
  }

  const sumPoints = (): string => {
    return props.stats.actions.reduce((sum, action) => sum + action.points, 0) + "";
  }

    return ( 
      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={props.style}
          >
          <Stack justifyContent={'space-between'} sx={{width:'100%'}}>
            <Stack direction={"row"} spacing={1} justifyContent={'space-between'}  sx={{width:'100%'}}>
              <Typography variant='body2' sx={getStyle()}>{props.stats.player.name +  " (" + EnumPlayerPosition[(props.stats.player.playerPosition as string) as keyof typeof EnumPlayerPosition] + " )"}</Typography>


              <Typography variant='body2' sx={getStyle()}>{sumPoints()}</Typography>
            </Stack>
          </Stack>
          </AccordionSummary>
          
          <AccordionDetails>
            <Stack direction="row" justifyContent={'space-between'}>
              <Typography variant="h6">Ukupno poena: </Typography>
              <Typography variant="h6">{sumPoints()}</Typography>
            </Stack>
            <Divider />
            {props.stats.actions.map((s)=> (
              <Stack direction="row" justifyContent={'space-between'}>
                <Typography variant={"body2"}>{getAction(s.actionType).name +":"} </Typography>
                <Typography variant={"body2"}>{s.points} </Typography>
              </Stack>
            ))}
          </AccordionDetails>
      </Accordion>
    );
}