import InputLabel from '@mui/material/InputLabel';
import { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Formation, {formations, getFormationImg} from '../../../utils/Formation';


interface FormationChooseProps{
  defaultFormation: Formation | null;
  onFormationChange: Function;
}

export default function FormationChoose(props: FormationChooseProps) {

  const [formation, setFormation] = useState(props.defaultFormation);

  const handleChange = (event: SelectChangeEvent) => {
    let f: Formation = formations.filter((f: Formation) => f.name === event.target.value)[0];
    setFormation(f);
    props.onFormationChange(f);
  };

  useEffect(() => {

    setFormation(props.defaultFormation);
    },[props.defaultFormation]
);

  return (
      <FormControl size="medium">
        <InputLabel id="formation-label">Formacija</InputLabel>
        <Select sx={{display: "flex", justifyContent:"space-between"}}
          id="formation-select"
          labelId="formation-label"
          label="Formacija"
          value={formation?formation.name:''}
          onChange={handleChange}
          autoWidth
        >
          {formations.map((f: Formation) => (
            <MenuItem sx={{justifyContent:"space-between"}} key={f.name} value={f.name}>{f.name} 
              {getFormationImg(f.name)}
                {/* <Avatar variant={"rounded"} alt={'captain'} src={captainImg } /> */}
            </MenuItem>
          )
          )}
        </Select>
      </FormControl>
  );

}