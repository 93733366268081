import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import TransferListSportTeamDto from '../../../model/transfer/TransferListSportTeamDto';
import { Stack } from '@mui/material';
import { getCountyFlagAvatar } from '../../../utils/CountryColors';
import { Typography } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CountrySelectionProps {
  onSelectionChange: Function,
  allNames: string[]
  allTeams: TransferListSportTeamDto[]
}

function getStyles(team: string, teams: readonly string[], theme: Theme) {
  return {
    fontWeight:
      teams.indexOf(team) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FilterTeamSelection(props: CountrySelectionProps) {

  //MUI select component is buggy when working with array objects, so I had to pass simply string[] as prop
  //that is why we have some additional checks on change and update two arrays obj[] and sting[]

  const theme = useTheme();
  // const [selectedTeams, setSelectedTeams] = React.useState<TransferListSportTeamDto[]>([]);
  const [selectedNames, setSelectedNames] = React.useState<string[] | undefined>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedNames>) => {
    const {
      target: { value },
    } = event;

    let values: string[] | undefined = typeof value === 'string' ? value.split(',') : value;
    setSelectedNames(values);
    props.onSelectionChange(values);
  };

  const  handleDelete = (event: Event, teamName: string) => {
    
    let copy: string[] = [...selectedNames!.filter(s => s !== teamName)];
    setSelectedNames(copy);
    props.onSelectionChange(copy);
  };


  return (
    <Stack sx={{height:"fit-content", minWidth:'200px'}}>
        <FormControl>
          <InputLabel id="demo-multiple-chip-label">Tim</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedNames}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}

            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} 
                  avatar={getCountyFlagAvatar(value)}
                  variant="outlined"
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  onDelete={(e) => handleDelete(e, value)}
                  
                  />
                ))}
              </Box>
            )}

            MenuProps={MenuProps}
          >
            {props.allNames.sort((a, b) => a.localeCompare(b)).map((team) => (
              <MenuItem
                key={team}
                value={team}
                style={getStyles(team, props.allNames, theme)}
              >
                <Stack direction={'row'} spacing={1} alignItems={'center'}><Stack>{getCountyFlagAvatar(team, {maxWidth:'20px', maxHeight:'20px'})}</Stack><Typography>{team}</Typography></Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
  );
}
