
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useAppContext } from "../../context/AuthContext";
import AdminService, { AdminServiceType } from "../../service/admin/AdminService";
import { useEffectOnce } from "../../utils/Utils";
import { useState } from "react";
import MatchDto from "../../model/match/MatchDto";
import { getCountyFlagAvatar } from '../../utils/CountryColors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UpdateMatchDto from "../../model/match/UpdateMatchDto";
import AdminPlayers from "./AdminPlayers";
import CreatePlayerActionDto from "../../model/playerpoints/CreatePlayerActionDto";


import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import EnumMatchPeriod from "../../utils/enum/EnumMatchPeriod";
import EventService, { EventServiceType } from "../../service/event/EventService";
import RoundDto from "../../model/event/RoundDto";
import EnumRoundStatus from "../../utils/enum/EnumRoundStatus";
import { AreYouSureDialog } from "./AreYouSureDialog";


export default function AdminPanel() {
    
    const { saveMatch, getLiveMatches, getCurrentRoundMatches, startMatch, changeMatchPeriod, endMatch, getSportTeamPlayers, postPlayerAction, getAllPlayerActions, postRemoveAction, startRound, stopRound} : AdminServiceType = AdminService();
    const { allRounds, refreshAllRounds }: EventServiceType = EventService();

    const { authData } = useAppContext();
    const [matches, setMatches] = useState<MatchDto[]>([]);
    const [matchToStart, setMatchToStart] = useState<MatchDto | ''>('');
    const [currentRoundMatches, setCurrentRoundMatches] = useState<MatchDto[]>([]);
    const [roundToChange, setRoundToChange] = useState<number>(-1);
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [areYouSureCallback, setAreYouSureCallback] = useState<() => void>(() => () => { });

    const handleCloseAreYouSure = () => {
        setOpenAreYouSure(false);
    };

    const handleConfirmAreYouSureStart = (id: number) => {
        startRound(id, onRoundStarted);
        handleCloseAreYouSure();
    };

    const handleConfirmAreYouSureStop = (id: number) => {
        stopRound(id, onRoundStopped);
        handleCloseAreYouSure();
    };

    const amIAllowed = (): boolean => {
        if (!authData)
            return false;
        return (authData.user && (authData.user.id === 3 || authData.user.id === 1 || authData.user.id === 45 || authData.user.id === 46 || authData.user.id === 47));
    }
    
    useEffectOnce(() => {
        getLiveMatches(onLiveMatchesRetreived);
        getCurrentRoundMatches(onCurrentRoundMatchesRetreived);

    });

    const onLiveMatchesRetreived = (m: MatchDto[]) => {
        setMatches(m);
    }
    
    const onCurrentRoundMatchesRetreived = (m: MatchDto[]) => {
        setCurrentRoundMatches(m);
    }

    const hostGoal = (m : MatchDto, diff: number) => {
        let ms: MatchDto[] = [...matches];
        ms.filter(mmm => mmm.id === m.id)[0].homeScore += diff;
        setMatches([...ms]);
    }

    const visitorGoal = (m: MatchDto, diff: number) => {
        let ms: MatchDto[] = [...matches];
        ms.filter(mmm => mmm.id === m.id)[0].visitorsScore += diff;
        setMatches([...ms]);
    }

    const sendPlayerAction = (dto: CreatePlayerActionDto) => {
        postPlayerAction(dto);
    }

    const removeAction = (actionId: number ) => {
        postRemoveAction (actionId);
    }

    const saveChanges = (m: MatchDto) => {
        let um: UpdateMatchDto = {id: m.id, homeScore: m.homeScore, visitorsScore: m.visitorsScore, matchStatus:m.matchStatus};
        saveMatch(um, onLiveMatchesRetreived);
    }
    
    const endLiveMatch = (m: MatchDto) => {
        endMatch(m.id, onLiveMatchesRetreived);
    }

    const handleStartMatch = () =>{
        if (matchToStart)
            startMatch(matchToStart.id, onLiveMatchesRetreived);
    }

    const handleMatchToStartChange = (event: SelectChangeEvent) => {
        console.log(event.target.value);
        const selectedMatchToStart: MatchDto = currentRoundMatches.filter((m:MatchDto) => (m.host.name + " : " + m.visitor.name) === event.target.value)[0];
        if (selectedMatchToStart != null)
            setMatchToStart(selectedMatchToStart);
    }  

    const handleMatchPeriodChange = (event: SelectChangeEvent, matchId: number) => {
        console.log (event.target.value + " : " + matchId + " . " + getEnumMatchPeriodOrder(event.target.value));
        changeMatchPeriod(matchId, {id:matchId, matchPeriod: getEnumMatchPeriodOrder(event.target.value)});
    }
    
    function getEnumMatchPeriodOrder(value: string): number | undefined {
        const enumValue = getEnumMatchPeriodValueByString(value);
        if (enumValue !== undefined) {
            return Object.values(EnumMatchPeriod).indexOf(enumValue);
        }
        return undefined;
    }    
    
    function getEnumRoundStatusOrder(value: string): number | undefined {
        const enumValue = getEnumRoundStatusValueByString(value);
        if (enumValue !== undefined) {
            return Object.values(EnumRoundStatus).indexOf(enumValue);
        }
        return undefined;
    }    

    function getEnumMatchPeriodValueByString(value: string): EnumMatchPeriod | undefined {
        const enumValues = Object.values(EnumMatchPeriod) as string[];
        const index = enumValues.indexOf(value);
        return index !== -1 ? EnumMatchPeriod[Object.keys(EnumMatchPeriod)[index] as keyof typeof EnumMatchPeriod] : undefined;
    }

    function getEnumRoundStatusValueByString(value: string): EnumRoundStatus | undefined {
        const enumValues = Object.values(EnumRoundStatus) as string[];
        const index = enumValues.indexOf(value);
        return index !== -1 ? EnumRoundStatus[Object.keys(EnumRoundStatus)[index] as keyof typeof EnumRoundStatus] : undefined;
    }

    const onRoundStarted = () => {
        getLiveMatches(onLiveMatchesRetreived);
        getCurrentRoundMatches(onCurrentRoundMatchesRetreived);
        refreshAllRounds();
    }
    
    const onRoundStopped =() => {
        getLiveMatches(onLiveMatchesRetreived);
        getCurrentRoundMatches(onCurrentRoundMatchesRetreived);
        refreshAllRounds();
    }

    const startRoundClicked = (id: number) => {
        console.log('startRoundClicked ' + id);
        setRoundToChange(id);
        setAreYouSureCallback(() => handleConfirmAreYouSureStart);
        setOpenAreYouSure(true);
    }
    
    const stopRoundClicked = (id: number) => {
        console.log('stopRoundClicked ' + id);
        setRoundToChange(id);
        setAreYouSureCallback(() => handleConfirmAreYouSureStop);
        setOpenAreYouSure(true);
    }

    const getAllRounds = () => {
        return allRounds && <Stack direction={"row"} spacing={3}>
            <Typography variant="h3">Runde</Typography>
            <Stack
                direction={"row"} 
                spacing ={2}>
                {allRounds.sort((a, b) => a.id - b.id).map((r:RoundDto) => (
                    <Stack 
                        sx={{
                            border: '2px solid black', // Add a border to outline the stack
                            padding: 1, // Add padding inside the stack
                            borderRadius: 1, // Optional: add rounded corners
                            backgroundColor: (getEnumRoundStatusOrder(r.roundStatus.toString()) === EnumRoundStatus.IN_PROGRESS) ? 'lightgreen' : 'lightcoral',
                        }} 
                        direction={"column"} 
                        justifyItems={"center"}
                    >
                        <Typography>{"Runda: "+ r.ord}</Typography>
                        
                        {

                            getEnumRoundStatusOrder(r.roundStatus.toString()) !== EnumRoundStatus.IN_PROGRESS?
                            (
                                <Button onClick={() => startRoundClicked(r.id)}>
                                    Start
                                </Button>
                            ):
                            (
                                <Button onClick={() => stopRoundClicked(r.id)}>
                                    Stop
                                </Button>
                            )
                        }
                    </Stack>
                    )
                )}
            </Stack>
            <AreYouSureDialog onClose={handleCloseAreYouSure} roundToChange={roundToChange} onConfirm={areYouSureCallback} open={openAreYouSure}/>
        </Stack>
    }

    const getCurrentRoundMatchesList = () => {
        return <Stack direction={"row"} padding={1}>
            <FormControl sx={{ minWidth: '200px'}} size="medium">
                <InputLabel id="period-label">Start meč</InputLabel>
                <Select sx={{display: "flex", justifyContent:"space-between"}}
                id="start-match"
                labelId="start-match"
                label="Start match"
                value={matchToStart? (matchToStart.host.name + " : " + matchToStart.visitor.name): ''}
                defaultValue={''}
                onChange={ handleMatchToStartChange}
                >
                    {
                    currentRoundMatches.map((m: MatchDto) => (
                        <MenuItem sx={{justifyContent:"space-between"}} key={m.id} value={m.host.name + " : " + m.visitor.name}>
                            {m.host.name + " : " + m.visitor.name}
                        </MenuItem>
                    )
                )}
                </Select>
            </FormControl>
                
            <Button onClick={() => handleStartMatch()}
                    color="primary" variant="contained"
                    disabled={matchToStart === ''}
                    style={{
                        backgroundColor: matchToStart === ''? "white": "blue",
                        opacity: matchToStart === ''? 0.8:1,
                    }}>
                Startuj meč
            </Button>
        </Stack>

    }

    const getMatch = (m: MatchDto) => {
        return (
            <Stack key={m.id}>
                <Stack direction={'row'} justifyContent="center" spacing={2} >
                    {getCountyFlagAvatar(m.host.name)}
                    
                    <Typography variant="h3">{m.homeScore}</Typography>
                    <Stack direction={'column'}>
                        <AddCircleIcon sx={{cursor:'pointer'}} onClick={() => hostGoal(m, 1)}/>
                        <RemoveCircleIcon sx={{cursor:'pointer'}} onClick={() => hostGoal(m, -1)}/>
                    </Stack>
                    <Typography variant="h3">:</Typography>
                    
                    <Stack direction={'column'}>
                        <AddCircleIcon sx={{cursor:'pointer'}} onClick={() => visitorGoal(m, 1)}/>
                        <RemoveCircleIcon sx={{cursor:'pointer'}} onClick={() => visitorGoal(m, -1)}/>
                    </Stack>
                    <Typography variant="h3">{m.visitorsScore}</Typography>
                    {getCountyFlagAvatar(m.visitor.name)}
                    <Button onClick={() => saveChanges(m)}>
                            Potvrdi
                    </Button>

                    
                    <FormControl sx={{ minWidth: '100px'}} size="medium">
                        <InputLabel id="period-label">Period</InputLabel>
                        <Select sx={{display: "flex", justifyContent:"space-between"}}
                        id="period-select"
                        labelId="period-label"
                        label="Period"
                        value={m.matchPeriod}
                        defaultValue={m.matchPeriod}
                        onChange={(event: SelectChangeEvent<EnumMatchPeriod>) => handleMatchPeriodChange(event, m.id)}
                        >
                        {Object.values(EnumMatchPeriod).filter(value => typeof value === 'string').map((value:string) => (
                            <MenuItem sx={{justifyContent:"space-between"}} key={value} value={value}>
                                {value}
                            </MenuItem>
                        )
                        )}
                        </Select>
                    </FormControl>


                    <Button onClick={() => endLiveMatch(m)}>
                            Kraj meča
                    </Button>
                </Stack>
                {(m && m!==undefined) && <AdminPlayers removeAction={removeAction} getSportTeamPlayers={getSportTeamPlayers} getAllPlayerActions={getAllPlayerActions} sendPlayerAction={sendPlayerAction} match={m}/>}
            </Stack>
        );
    }

    if (!amIAllowed)
        return <div> nemaš pristup ovom sadržaju.</div>
    if (matches.length === 0)
        return (
            <Stack direction={"column"}>
                {getAllRounds()}
                {getCurrentRoundMatchesList()}
            </Stack>  
        )
    return (
        <Stack direction={"column"}>
            {getAllRounds()}
            {getCurrentRoundMatchesList()}
            <Stack direction={"column"}>
                {
                    matches.map((m) => (
                        getMatch(m)
                    ))
                }
            </Stack>
        </Stack>
    )
}