import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useMediaQuery } from 'react-responsive';

interface TogglePoistionProps {
  onPositionChange: Function
}

export default function PositionToggle(props:TogglePoistionProps) {
  const [pos, setPos] = React.useState<string | null>('');
  
  const isSmallMobile = useMediaQuery({ query: `(max-width: 550px)` });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPos: string | null,
  ) => {
    setPos(newPos);
    props.onPositionChange(newPos);
  };

  const getStyle = () => {
    if (isSmallMobile)
      return {fontSize:'13px', padding:'10px 3px 10px 3px'}
    return{};
  }

  return (
    <ToggleButtonGroup sx={{height:'fit-content', paddingBottom:'10px'}}
      value={pos}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton style={getStyle()} value="ALL">
        Svi
      </ToggleButton>
      <ToggleButton style={getStyle()} value="GK">
        Golmani
      </ToggleButton>
      <ToggleButton style={getStyle()} value="DF">
        Odbrana
      </ToggleButton>
      <ToggleButton style={getStyle()} value="MF">
        Sredina
      </ToggleButton>
      <ToggleButton style={getStyle()} value="FW">
        Napad
      </ToggleButton>
    </ToggleButtonGroup>
  );
}