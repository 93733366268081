import { useAppContext } from "../../context/AuthContext";
import { LeagueController, LeagueControllerType } from "../../controller/private/LeagueController";
import MessageType from "../../utils/MessageType";
import { handleErrorMessage } from "../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import CreateLeagueDto from "../../model/league/CreateLeagueDto";

interface LeagueServiceType { 
  getLeagues: Function;
  getLeague: Function;
  requestJoinLeague: Function;
  requestCreateLeague: Function;
}

export default function LeagueService(): LeagueServiceType {
  const { showMessage, setBlockUI } = useAppContext();
  const { axiosGetLeagues, axiosGetGlobalLeague, axiosJoinLeague, axiosCreateLeague, axiosGetPrivateLeague}: LeagueControllerType = LeagueController();

  const navigate = useNavigate();

  const getLeagues = (onLeaguesRetreived: Function) =>{

    setBlockUI(true);
    axiosGetLeagues()
      .then((result: any) => {
        onLeaguesRetreived(result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(() => {
        setBlockUI(false);
      }); 
  };

  const getLeague = (leagueId: number, onLeagueRetreived: Function) => {

    setBlockUI(true);

    if (leagueId === 0)
      axiosGetGlobalLeague()
        .then((result: any) => {
          onLeagueRetreived(result.data);
        })
        .catch((error: any) => {
          handleErrorMessage(showMessage, error);
        })
        .finally(() => {
          setBlockUI(false);
        }); 
    else 
      axiosGetPrivateLeague(leagueId)
      .then((result: any) => {
        onLeagueRetreived(result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(() => {
        setBlockUI(false);
      });
  };

  const requestJoinLeague = (code: String) => {
    setBlockUI(true);
    axiosJoinLeague(code)
    .then(() => {
      showMessage('Uspešno ste se pridružili ligi!', MessageType.SUCCESS);
      navigate(0);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    });
  }


  const requestCreateLeague = (dto: CreateLeagueDto) => {
    setBlockUI(true);
    axiosCreateLeague(dto)
    .then(() => {
      showMessage('Uspešno ste se kreirali ligu. Pozovite prijatelje!', MessageType.SUCCESS);
      navigate(0);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    });
  }

  return { getLeagues, getLeague, requestJoinLeague, requestCreateLeague};
}


export type { LeagueServiceType };


