import { useState } from "react";
import { useAppContext } from "../../context/AuthContext";
import { TransferController, TransferControllerType } from "../../controller/private/TransferController";
import TransferListSportTeamDto from "../../model/transfer/TransferListSportTeamDto";
import { handleErrorMessage, useEffectOnce } from "../../utils/Utils";
import PlayerDto from "../../model/player/PlayerDto";

interface TransferServiceType {
  // getAllTeams: any,
  allTeams: TransferListSportTeamDto[],
  allPlayers: PlayerDto[],
  costRange: number[]
}

export default function TransferService(): TransferServiceType {
  const { showMessage, setBlockUI } = useAppContext();
  const { axiosGetAllTeams }: TransferControllerType = TransferController();
  const [allTeams, setAllTeams] = useState<TransferListSportTeamDto[]>([]);
  const [allPlayers, setAllPlayers] = useState<PlayerDto[]>([]);
  const [costRange, setCostRange] = useState<number[]>([0,100])
  
  function setAllPlayersFromTeams(teams: TransferListSportTeamDto[]) {

    let players: PlayerDto[] = teams.flatMap(t => {

      t.players.forEach(p => {
        p.teamName = t.name;
      });

      return t.players
    });
    let arr: number[] = players.map(p => p.cost);    
    setCostRange([Math.min(...arr), Math.max(...arr)]);

    setAllPlayers(players);
  }

  useEffectOnce(() => {
    setBlockUI(true);
    axiosGetAllTeams()
      .then((result: any) => {
        setBlockUI(false);
        setAll(result.data);
      })
      .catch((error: any) => {
        setBlockUI(false);
        handleErrorMessage(showMessage, error);
      });
  });

  function setAll(data: any){
    setAllTeams(data);
    setAllPlayersFromTeams(data);
  }
  
  return { allTeams, allPlayers, costRange};
}

export type { TransferServiceType };


