import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Chip } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { useEffectOnce } from '../../../utils/Utils';
import { Typography } from '@mui/material';

interface PriceSliderProps{
    minValue: number;
    maxValue: number;
    onSliderChange: Function;
}

function valuetext(value: number) {
  return `${value} M`;
}

const minDistance: number = 0.5;

export default function PriceSlider(props: PriceSliderProps) {
      const [value, setValue] = React.useState<number[]>([]);
    
      const handleChange1 = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
      ) => {
        if (!Array.isArray(newValue)) {
          return;
        }
    
        if (activeThumb === 0) {
          setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
          setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
        props.onSliderChange(value);
      };
    
      useEffect(() => {
        if (props.minValue !== undefined && value.length <= 0) {
            setValue([props.minValue, props.maxValue]);                  
        }
      }, [props.minValue, props.maxValue, value.length]);

      return (
        <Stack direction="column" sx={{height:'fit-content', width:'200px', alignContent:"center"}}>
            <Slider sx={{width:'150px', marginLeft:'25px'}}
                getAriaLabel={() => 'Minimum distance'}
                value={value}
                onChange={handleChange1}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                step={0.5}
                disableSwap
                min={props.minValue}
                max={props.maxValue}
            />
            <Stack direction="row" justifyContent={'space-between'} sx={{width:'100%'}}>
              
                <Chip label={value.length <=0 ? "-": value[0]+" M"}  variant="outlined"/>
                <Typography>cena</Typography>
                <Chip label={value.length <=0 ? "-": value[1]+" M"}  variant="outlined"/>
            </Stack>

        </Stack>
      );
    }
    