import { Avatar } from "@material-ui/core";

export const CountryColors = {
  jersey: 'white',
  text: 'black'
}

enum EnumCountryCodes {

  'Argentina'='AR',
  'Australija'='AU',
  'Belgija'='BE',
  'Brazil'='BR',
  'Danska'='DK',
  'Ekvador'='EC',
  'Engleska'='GB-ENG',
  'Francuska'='FR',
  'Gana'='GH',
  'Holandija'='NL',
  'Hrvatska'='HR',
  'Iran'='IR',
  'Japan'='JP',
  'Južna Koreja'='KR',
  'Kamerun'='CM',
  'Kanada'='CA',
  'Katar'='QA',
  'Kostarika'='CR',
  'Maroko'='MA',
  'Meksiko'='MX',
  'Nemačka'='DE',
  'Poljska'='PL',
  'Portugal'='PT',
  'SAD'='US',
  'Saudijska Arabija'='SA',
  'Senegal'='SN',
  'Španija'='ES',
  'Srbija'='RS',
  'Švajcarska'='CH',
  'Tunis'='TN',
  'Urugvaj'='UY',
  'Vels'='GB-WLS',

  //euro 2024 counries 
  'Mađarska'='HU',
  'Italija'='IT',
  'Albanija'='AL',
  'Slovenija'='SI',
  'Austrija'='AT',
  'Slovačka'='SK',
  'Rumunija'='RO',
  'Ukrajina'='UA',
  'Češka'='CZ',
  'Turska'='TR',
  'Gruzija'='GE',
  'Škotska'='GB-SCT'
}


export enum EnumCountryColors {

  'Argentina'='#74ACDF',
  'Australija'='#F1BB1D',
  'Belgija'='#D60522',
  'Brazil'='#FBD42E',
  'Danska'='#AF232C',
  'Ekvador'='#C0A201',
  'Engleska'='#FFFFFF',
  'Francuska'='#2C3262',
  'Gana'='#FFFFFF',
  'Holandija'='#E25B2E',
  'Hrvatska'='#DF293F',
  'Iran'='#FFFFFF',
  'Japan'='#2A3666',
  'Južna Koreja'='#E20105',
  'Kamerun'='#009266',
  'Kanada'='#D61A33',
  'Katar'='#A12E3B',
  'Kostarika'='#FE0130',
  'Maroko'='#F63025',
  'Meksiko'='#006456',
  'Nemačka'='#FFFFFF',
  'Poljska'='#FFFFFF',
  'Portugal'='#92142A',
  'SAD'='#FFFFFF',
  'Saudijska Arabija'='#FFFFFF',
  'Senegal'='#017767',
  'Španija'='#C91818',
  'Srbija'='#8C060D',
  'Švajcarska'='#E81010',
  'Tunis'='#FFFFFF',
  'Urugvaj'='#4B7CAE',
  'Vels'='#D62C35',
  //euro 2024 additional countries
  'Mađarska'='#CD2A3E',
  'Italija'='#007FFF',
  'Albanija'='#E31D1C',
  'Slovenija'='#005DAC',
  'Austrija'='#ED2939',
  'Slovačka'='#003DA5',
  'Rumunija'='#FFDD00',
  'Ukrajina'='#0057B7',
  'Češka'='#D7141A',
  'Turska'='#E30A17',
  'Gruzija'='#DA291C',
  'Škotska'='#003078', 
}

export const getCountyFlagAvatar = (countryName: string, style?: {})  => {
  // return <Avatar alt={countryName} src={"https://flagcdn.com/w40/ca.png"} />
  return <Avatar onContextMenu={(e)=>{e.preventDefault()}} alt={countryName} style={style===undefined?{}:style} src={"https://flagcdn.com/h40/" + getCountryCode(countryName) + ".png"} />
}

export const getColorForCountry = (countryName: string) : typeof CountryColors  => {
  let jersey: string = Object.values(EnumCountryColors)[Object.keys(EnumCountryColors).indexOf(countryName)];
  return {jersey: jersey, text: (jersey === 'white' || jersey === '#FFFFFF')?'black': 'white'};
}

export const getCountryName12chr = (countryName: string): string =>{
  return countryName === 'Saudijska Arabija'?'S. Arabija':countryName;
} 

const getCountryCode = (countryName: string) =>{
  return Object.values(EnumCountryCodes)[Object.keys(EnumCountryCodes).indexOf(countryName)].toLowerCase();
} 