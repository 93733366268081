import { Avatar } from "@material-ui/core";


import f442 from "../assets/img/formations/442.png";
import f433 from "../assets/img/formations/433.png";
import f451 from "../assets/img/formations/451.png";
import f532 from "../assets/img/formations/532.png";
import f541 from "../assets/img/formations/541.png";
import f343 from "../assets/img/formations/343.png";
import f352 from "../assets/img/formations/352.png";
import EnumPlayerPosition from "./enum/EnumPlayerPosition";
import PlayerDto from "../model/player/PlayerDto";
import { getPlayersOnPosition } from "./TransferValidation";
import PlayerMatchPointsDto from "../model/playerpoints/PlayerMatchPointsDto";

export default class Formation {
    dfCount: number;
    mfCount: number;
    fwCount: number;
    name: string;
    src: string;

    constructor (dfCount: number, mfCount: number, fwCount: number, name: string){
        this.dfCount = dfCount;
        this.mfCount = mfCount;
        this.fwCount = fwCount;
        this.name = name;
        this.src = dfCount + '' +mfCount + '' + fwCount + '.png';
    }    

}


const positionsOrder: EnumPlayerPosition[]  = [EnumPlayerPosition.GOALKEEPER, EnumPlayerPosition.DEFENDER, EnumPlayerPosition.MIDFIELDER, EnumPlayerPosition.FORWARD];

const FormationImages = {
    '4-4-2': f442,
    '4-3-3': f433,
    '4-5-1': f451,
    '5-4-1': f541,
    '5-3-2': f532,
    '3-5-2': f352,
    '3-4-3': f343,
}

export const formations: Formation[] = [new Formation(4, 4, 2, '4-4-2'), new Formation(4, 3, 3, '4-3-3'),
                                        new Formation(4, 5, 1, '4-5-1'), new Formation(5, 4, 1, '5-4-1'), 
                                        new Formation(5, 3, 2, '5-3-2'), new Formation(3, 4, 3, '3-4-3'), 
                                        new Formation(3, 5, 2, '3-5-2'),  ];

export  const getFormationImg = (name: string) => {
    let src = Object.values(FormationImages)[Object.keys(FormationImages).indexOf(name)];
    return <Avatar src={src} />;
}

// export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
//   list.reduce((previous, currentItem) => {
//     const group = getKey(currentItem);
//     if (!previous[group]) previous[group] = [];
//     previous[group].push(currentItem);
//     return previous;
//   }, {} as Record<K, T[]>);

// export const getFormationFrom = (grouped: any): Formation =>{
//     console.log(grouped);
//     // console.log(Object.keys(EnumPlayerPosition.DEFENDER).indexOf('DEFENDER'));
//     console.log(O Object.keys(grouped) as Array<keyof typeof grouped>);
//   console.log(Object.values(typeof grouped)[Object.keys(typeof grouped).indexOf('DEFENDER')]);
//   let def: number = Object.values(typeof grouped)[Object.keys(typeof grouped).indexOf(EnumPlayerPosition.DEFENDER)].length;

//   console.log(def);
//   return getFormation(grouped.get(EnumPlayerPosition.DEFENDER), grouped.get(EnumPlayerPosition.MIDFIELDER), grouped.get(EnumPlayerPosition.FORWARD));  
// }


export const getBench = (players: PlayerDto[], s11:PlayerDto[]) : PlayerDto[] => {
    return players.filter((p: PlayerDto)  => s11.map((p11: PlayerDto) => p11.id).indexOf(p.id) < 0).sort((a, b) => Object.keys(EnumPlayerPosition).indexOf(a.playerPosition) - Object.keys(EnumPlayerPosition).indexOf(b.playerPosition));
}

export const getBenchForPosition = (players: PlayerDto[], s11:PlayerDto[], position: EnumPlayerPosition) : PlayerDto[] => {
    return getBench(players, s11).filter(p => position === Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(p.playerPosition)]);
}

export const getChangablePlayersForPosition = (cp: ChangablePlayers[], position: EnumPlayerPosition) : ChangablePlayers | null=> {
    let res: ChangablePlayers = cp.filter(p => p.position === position)[0];
    return res === undefined? null: res;
}

export const getFormationFrom = (df: number, mf: number, fw: number): Formation => {
    return getFormation(df, mf, fw);  
}

export const getFormation = (df: number, mf: number, fw: number): Formation =>{
    let form = formations.filter((f: Formation) => f.dfCount === df && f.mfCount === mf && f.fwCount ===fw)[0];
    return form===null? formations[0]: form; 
}

export class ChangablePlayers{
    position: EnumPlayerPosition;
    players: PlayerDto[];
    count: number;
    chosen: PlayerDto[] = [];

    constructor (position: EnumPlayerPosition, players: PlayerDto[], count: number){
        this.position = position;
        this.players = players;
        this.count = count;
    }    

    setChosen (players: PlayerDto[]) {
        this.chosen = players;
    }
}

// export const getPossibleSubIn = (oldFormation: Formation, newFormation: Formation, starting11: PlayerDto[], players: PlayerDto[]) => {
//     let positions: ChangablePlayers [] = [];
    
//     positions.push(new ChangablePlayers(EnumPlayerPosition.DEFENDER, getChangablePlayersIn(EnumPlayerPosition.DEFENDER, oldFormation, newFormation, starting11, players)));
// }

export const getChangablePlayersIn = (oldFormation: Formation, newFormation: Formation, starting11: PlayerDto[], players: PlayerDto[]): ChangablePlayers[] => {

    let cpList : ChangablePlayers[] = [];
    if (oldFormation.dfCount < newFormation.dfCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.DEFENDER, getBenchForPosition(players, starting11, EnumPlayerPosition.DEFENDER), newFormation.dfCount - oldFormation.dfCount));
    if (oldFormation.mfCount < newFormation.mfCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.MIDFIELDER, getBenchForPosition(players, starting11, EnumPlayerPosition.MIDFIELDER), newFormation.mfCount - oldFormation.mfCount));
    if (oldFormation.fwCount < newFormation.fwCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.FORWARD, getBenchForPosition(players, starting11, EnumPlayerPosition.FORWARD), newFormation.fwCount - oldFormation.fwCount));
    
    return cpList;

}


export const getChangablePlayersOut = (oldFormation: Formation, newFormation: Formation, starting11: PlayerDto[]): ChangablePlayers[] => {

    let cpList : ChangablePlayers[] = [];
    if (oldFormation.dfCount > newFormation.dfCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.DEFENDER, getPlayersOnPosition(starting11, EnumPlayerPosition.DEFENDER), oldFormation.dfCount - newFormation.dfCount));
    if (oldFormation.mfCount > newFormation.mfCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.MIDFIELDER, getPlayersOnPosition(starting11, EnumPlayerPosition.MIDFIELDER), oldFormation.mfCount - newFormation.mfCount));
    if (oldFormation.fwCount > newFormation.fwCount)
        cpList.push(new ChangablePlayers (EnumPlayerPosition.FORWARD, getPlayersOnPosition(starting11, EnumPlayerPosition.FORWARD), oldFormation.fwCount - newFormation.fwCount));
    
    return cpList;

}

export const sortPositions = (a: PlayerDto, b: PlayerDto): number => {
    let aString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(a.playerPosition)];
    let bString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(b.playerPosition)];

    return positionsOrder.indexOf(aString) - positionsOrder.indexOf(bString);
}

export const sortByPlayerPosition = (list: PlayerMatchPointsDto[]): PlayerMatchPointsDto[] => {
    return list.sort((a, b) => {
        let aString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(a.player.playerPosition)];
        let bString = Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(b.player.playerPosition)];
    
        return positionsOrder.indexOf(aString) - positionsOrder.indexOf(bString);
    });
  };


export const getPossibleSubOut = (oldFormation: Formation, newFormation: Formation) => {
    
}