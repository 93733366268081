import { Box, Container, createTheme, CssBaseline, TextField, ThemeProvider, Typography } from "@material-ui/core";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TeamDto from "../../../model/team/TeamDto";

interface NewTeamProps{
  team: TeamDto | null | undefined;
  onCreateTeam: Function;
}

export default function NewTeam(props:NewTeamProps) {

  const navigate = useNavigate();
  const theme = createTheme();

  // const [team, setTeam] = useState<TeamDto | null | undefined>(props.team);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    props.onCreateTeam({name:  data.get('teamName')!});
  };

  // useEffect(() => {setTeam(props.team)},[props.team]);

  const goToTransfers = () => {
    navigate('/transfers');
  }

  if (props.team)
      return ( 
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography component="h1" variant="h5">
          Uspešno ste kreirali tim: {props.team.name}
        </Typography>
          <Box sx={{ mt: 1 }} onClick={goToTransfers}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Odaberi igrače
                </Button>
          </Box>
        </Container>
      </ThemeProvider>
    );

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Typography component="h1" variant="h5">
            Kreiraj svoj tim!
          </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="teamName"
                label="Naziv tima"
                name="teamName"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Kreiraj
              </Button>
            </Box>

        </Container>
      </ThemeProvider>
    );
}