import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import CreateTeamDto from "../../model/team/CreateTeamDto";
import SaveSquadDto from "../../model/team/SaveSquadDto";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";

interface TeamControllerType {
  axiosGetMyTeam: any;
  axiosCreateTeam: any;
  axiosCreateRoundSquad: any;
  axiosGetRoundSquad: any;
  axiosSaveSquad: any;
}

export function TeamController(): TeamControllerType {
  const { authData } = useAppContext();

  const axiosGetMyTeam = () => {
    return axios.get(Endpoints.TEAM, axiosConfig(authData!.token));
  };

  const axiosGetRoundSquad = (eventId: number, roundId: number) => {
    return axios.get(`${Endpoints.TEAM}/${eventId}/${roundId}/get-my-roundSquad`, axiosConfig(authData!.token));
  };

  const axiosCreateTeam = (data: CreateTeamDto) => {
    return axios.post(Endpoints.TEAM + '/create-team', data, axiosConfig(authData!.token));
  }

  const axiosCreateRoundSquad = (eventId: number, roundId: number, teamId: number, data: CreateTeamDto) => {
    return axios.post(`${Endpoints.TEAM}/${eventId}/${roundId}/${teamId}/set-round-squad`, data, axiosConfig(authData!.token));
  }

  const axiosSaveSquad =  (eventId: number, roundId: number, teamId: number, data: SaveSquadDto) => {
    return axios.post(`${Endpoints.TEAM}/${eventId}/${roundId}/${teamId}/save-starting11`, data, axiosConfig(authData!.token));
  }

  return { axiosGetMyTeam, axiosCreateTeam, axiosCreateRoundSquad, axiosGetRoundSquad, axiosSaveSquad };
}

export type { TeamControllerType };
