import { useAppContext } from "../../context/AuthContext";
import { PlayerPointsController, PlayerPointsControllerType } from "../../controller/private/PlayerPointsController";
import { handleErrorMessage } from "../../utils/Utils";

interface PlayerPointsServiceType {
    requestPlayerStats: Function;
    getPlayersPoints: Function;
    getMatchActionsPerPlayer: Function;
    getAllPlayerActions: Function;
  }
  
  export default function PlayerPointsService(): PlayerPointsServiceType {

    const { showMessage, setBlockUI } = useAppContext();
    
    const { axiosGetPlayersPoints, axiosGetPlayerPoints, axiosGetMatchActionsPerPlayer, axiosGetAllPlayerActions }: PlayerPointsControllerType = PlayerPointsController();
    const getPlayersPoints = (roundId: number, onRetrieved: Function) => {
        setBlockUI(true);
        axiosGetPlayersPoints(roundId)
        .then((result: any) => {
          if (result.data)
            onRetrieved(result.data);
        })
        .catch((error: any) => {
          handleErrorMessage(showMessage, error);
        })
        .finally(() => {
            setBlockUI(false);
        });
    }
    
    const getAllPlayerActions = (matchId: number, onPlayerActionsRetreived: Function ) => {
      axiosGetAllPlayerActions(matchId)
      .then((result: any) => {
        setBlockUI(true);
        onPlayerActionsRetreived(result.data)
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(() => {
        setBlockUI(false);
      }); 
    }
    
    const getMatchActionsPerPlayer = (matchId: number, onPlayerActionsRetreived: Function ) => {
      axiosGetMatchActionsPerPlayer(matchId)
      .then((result: any) => {
        setBlockUI(true);
        onPlayerActionsRetreived(result.data)
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(() => {
        setBlockUI(false);
      }); 
    }

    const requestPlayerStats = (playerId: number, onRetrieved: Function) => {
      setBlockUI(true);
      axiosGetPlayerPoints(playerId)
      .then((result: any) => {
        if (result.data)
          onRetrieved(result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(() => {
          setBlockUI(false);
      });
  }
    
    return { requestPlayerStats, getPlayersPoints, getMatchActionsPerPlayer, getAllPlayerActions};
}

export type { PlayerPointsServiceType };
