import Stack from '@mui/material/Stack';
import { Paper, createTheme, ThemeProvider, Typography } from '@mui/material';
import { getPlayersOnPosition } from "../../../utils/TransferValidation";
import EnumPlayerPosition from "../../../utils/enum/EnumPlayerPosition";
import Player from "./player/Player";
import RoundSquadDto from "../../../model/team/RoundSquadDto";
import pitchImg from "../../../assets/img/pitch-3.png";
import PlayerDto from '../../../model/player/PlayerDto';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from "react-dnd"
import { TouchTransition } from "react-dnd-multi-backend";
// import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import { useEffect, useState } from 'react'; 
import FormationChoose from './FormationChoose';
import Formation, { ChangablePlayers, getBench, getChangablePlayersIn, getChangablePlayersOut, getFormationFrom } from '../../../utils/Formation';
import SubInOutDialog from './SubInOutDialog';
import { purple } from '@mui/material/colors';
import CaptainChoose from './CaptainChoose';
import Button from "@material-ui/core/Button";
import AlertDialog from '../../../utils/AlertDialog';
import Schedule from '../schedule/Schedule';
import EnumRoundStatus from '../../../utils/enum/EnumRoundStatus';
import EventService, { EventServiceType } from '../../../service/event/EventService';
import RoundDto from '../../../model/event/RoundDto';
import PlayerPointsService, {PlayerPointsServiceType} from '../../../service/playerpoints/PlayerPointsService';
import PlayerPointsDto from '../../../model/playerpoints/PlayerPointsDto';
import PointsDto from '../../../model/playerpoints/PointsDto';
import PlayerPoints from '../../../model/player/PlayerPoints';
import EnumMatchStatus from '../../../utils/enum/EnumMatchStatus';
import TeamDto from '../../../model/team/TeamDto';
import RoundPoints from '../../../model/round/RoundPoints';
import HelpIcon from '@mui/icons-material/Help';

interface Starting11Props{
    roundSquad: RoundSquadDto | null | undefined;
    onSaveChanges: Function;
    onRoundChanged: Function;
    team: TeamDto | null | undefined;
}

export default function Starting11 (props: Starting11Props) {

    const [ roundId, setRoundId] = useState<number>(-1);
    const [ squad, setSquad] = useState<RoundSquadDto | null>(null);
    const [ counter, setCounter] = useState<number>(0);
    const [ isDraggingActive, setDraggingActive] = useState<boolean>(false);
    const [ openSubInOut, setOpenSubInOut] = useState<boolean>(false);
    const [ possibleSubIn, setPossibleSubIn] = useState<ChangablePlayers[]>([]);
    const [ possibleSubOut, setPossibleSubOut] = useState<ChangablePlayers[]>([]);
    const [ oldFormation, setOldFormation] = useState<Formation | null>(null);
    const [ newFormation, setNewFormation] = useState<Formation>();
    const [ captain, setCaptain] = useState<PlayerDto>();
    const [ noChanges, setNoChanges ] = useState<boolean>(true);
    const [ openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
    const [ alertInfo, setAlertInfo] = useState<{title: string, text: string}>({title: '', text: ''});
    const [ playerPoints, setPlayerPoints] = useState<PlayerPoints[]>([]);
    const [ roundPoints, setRoundPoints] = useState<RoundPoints[]>([]);

    const [touchScreen, setTouchScreen] = useState<boolean>(false);

    const { allRounds }: EventServiceType = EventService();
    const { getPlayersPoints } : PlayerPointsServiceType = PlayerPointsService();

    const handleSelectedIn = (subIn: PlayerDto[], cp: ChangablePlayers) => {
        let ind: number = possibleSubIn.indexOf(cp);
        cp.setChosen([...subIn]);
        let copy: ChangablePlayers[] = [...possibleSubIn];
        copy[ind] = cp;
        setPossibleSubIn(copy);
    }
    
    const handleSelectedOut = (subOut: PlayerDto[], cp: ChangablePlayers) => {
        let ind: number = possibleSubOut.indexOf(cp);
        cp.setChosen([...subOut]);
        let copy: ChangablePlayers[] = [...possibleSubOut];
        copy[ind] = cp;
        setPossibleSubOut(copy);
    }    

    const handleCaptainChange = (c: PlayerDto) => {
        let s: RoundSquadDto = {...squad!};
        s.captain = c;
        setCaptain(c);
        setNoChanges(false);
        setSquad(s);
    }

    const saveChanges = () => {
        if (!captain || squad!.starting11.filter((p: PlayerDto) => p.id === captain.id).length <= 0){
            setAlertInfo({title: 'Izaberite kapitena!', text: 'Jedan igrač iz startnih 11 mora biti kapiten.'});
            setOpenAlertDialog(true);
            return;
        }
        props.onSaveChanges(squad, roundId);
        setNoChanges(true);
    }

    const cancelChanges = () => {
        setSquad(props.roundSquad!);
        setNoChanges(true);
        // props.onCancelChanges();
    }

    const showHelp = () => {
        setAlertInfo({title: 'Odaberi startnih 11!', text: 'Prevlačenjem igrača postavi 11 igrača na teren. Jedino igrači na terenu donose bodove.'});
        setOpenAlertDialog(true);
    }


    const getRoundById = (roundId: number): RoundDto => {
        return allRounds!.filter(r => r.id === roundId)[0];
    }    

    const getIsValidSubIn = (): boolean => {
        return possibleSubIn.filter(p => p.chosen.length !== p.count).length === 0 && possibleSubOut.filter(p => p.chosen.length !== p.count).length === 0;
    }

    const onDraggingActive = (isActive: boolean) => {
        setDraggingActive(isActive);
    }
    
    const theme = createTheme({

        palette: {
            primary: purple,
          },

        components: {
          // Name of the component
          MuiPaper: {
            styleOverrides: {
              root: {
                // width: '100%',
                display: 'inline-grid',
                justifyContent: 'center',
              },
            },
          },

          MuiSelect: { 
            styleOverrides:  {         
                select: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    
                  },   
                }
          } ,
          MuiList: {
            styleOverrides:{
                root:{
                    minWidth: '150px'
                },   
            },
          }
        }
    });


    useEffect(() => {

        setTouchScreen(window.matchMedia("(pointer: coarse)").matches);
        

        if (squad === null && props.roundSquad){
            let s11: PlayerDto [] = [...props.roundSquad!.starting11];
            let rs: RoundSquadDto = {...props.roundSquad!};
            setRoundId(rs.round.id);
            rs.starting11 = s11;
            setSquad(rs);
            setCaptain(rs.captain);
        }
        },[]
    );

    useEffect(() => {

        if (noChanges)
            window.onbeforeunload = null;
        else
            window.onbeforeunload = function() {
                return "Izmene neće biti sačuvane?";
            }        
      }, [noChanges])
      
    useEffect(() => {
        setCounter(counter+1);
            if (squad && squad.starting11){
                setOldFormation(getCurrentFormation());
            }
            },[squad]
    );
    
    useEffect(() => {
        if (props.team && props.team.squads)
            recalculate();
        },[props.team]
    );

    useEffect(() => {
        if (props.roundSquad){
            setSquad(props.roundSquad);
            setCaptain(props.roundSquad.captain)
            setRoundId(props.roundSquad.round.id);
            if ( Object.values(EnumRoundStatus).indexOf(props.roundSquad.round.roundStatus) !== EnumRoundStatus.COMING)
                getPlayersPoints(props.roundSquad.round.id, onPlayersPointsReceived);
        }
        },[props.roundSquad]
    );

    const recalculate = () => {
        let points: RoundPoints[] = [];
        props.team!.squads!.forEach(element => {
            points.push({round:element.round.id, points: element.points});
        });

        setRoundPoints(points);
    }

    const onPlayersPointsReceived = (playerPointsList: PlayerPointsDto) => {

        let list: PlayerPoints[] = [];
        props.roundSquad!.players.forEach(p => {
            let f: PointsDto[] = (playerPointsList.finished.filter(pf => pf.playerId === p.id));
            let l: PointsDto[] = (playerPointsList.live.filter(pf => pf.playerId === p.id));
            if (f && f.length > 0){
                list.push({id:f[0].playerId, points: f[0].points, matchStatus: EnumMatchStatus.FINISHED});
            }
            else if (l && l.length > 0){
                list.push({id:l[0].playerId, points: l[0].points, matchStatus: EnumMatchStatus.LIVE});
            }
        });

        setPlayerPoints(list);
    }

    const isCaptain = (player: PlayerDto): boolean => {
        if (!captain)
            return false;
        return player.id === captain!.id;
    }

    const handleSubInOutDialogClosed = () =>{
        setOpenSubInOut(false);
        setOldFormation({...getCurrentFormation()});
    }

    const handleSubInOutDialogConfirmed = () =>{
        if (getIsValidSubIn())
            changeFormation();
        setOpenSubInOut(false);
    }

    const changeFormation = () => {
        let s: PlayerDto[] = [...squad!.starting11]
        possibleSubOut.forEach((c: ChangablePlayers) => {
            c.chosen.forEach((p: PlayerDto) => {
                s = s.filter(rp => rp.id !== p.id); 
            });
        });

        possibleSubIn.forEach((c: ChangablePlayers) => {
            c.chosen.forEach((p: PlayerDto) => {
                s.push(p);
            });
        });

        let copy: RoundSquadDto = {...squad!};
        copy.starting11 = s;
        setSquad(copy);
        setNoChanges(false);
    }

    const handleSubstitute = (subIn: number, subOut: number) => {
        let s11: PlayerDto[] = [...squad!.starting11];
        let ps: PlayerDto[] = [...squad!.players];
        let firstIndex: number = s11.map(p => p.id).indexOf(subIn);
        let secondIndex: number = s11.map(p => p.id).indexOf(subOut);
        
        let rs: RoundSquadDto| null = {...squad!};

        if (firstIndex >= 0 && secondIndex >= 0){
            [s11[firstIndex], s11[secondIndex]] = [ s11[secondIndex],  s11[firstIndex]];
        }
        else if (firstIndex >= 0){
            let secPlayer = squad!.players.find(p => p.id === subOut)!;
            s11[firstIndex] = secPlayer;
        }
        else if (secondIndex >= 0){
            let firstPlayer = squad!.players.find(p => p.id === subIn)!;
            s11[secondIndex] = firstPlayer;
        }
        else {
            [ps[firstIndex], ps[secondIndex]] = [ ps[secondIndex],  ps[firstIndex]];
            rs!.players = [...ps];
        }

        rs!.starting11 = [...s11];
        setSquad(rs);
        setNoChanges(false);
    }

    const getPoints = (id: number): PlayerPoints | null=>{

        let ppoints: PlayerPoints[] = playerPoints.filter(pp => pp.id === id);
        if (!ppoints || ppoints.length === 0){
            if (squad && Object.values(EnumRoundStatus).indexOf(squad!.round.roundStatus) === EnumRoundStatus.IN_PROGRESS)
                return {id:id, points:0, matchStatus:EnumMatchStatus.FINISHED};
            return null;
        }
        else
            return ppoints[0];
    }

    const getDndBackend= () => {
        return touchScreen? TouchBackend: HTML5Backend;
    }
    
    const getPlayer = (p: PlayerDto) =>{
        return <Player 
                    onSubstitute={handleSubstitute}  
                    setDraggingActive={onDraggingActive} 
                    isDraggingActive={isDraggingActive} 
                    key={p.id} 
                    player={p}
                    points={getPoints(p.id)}
                    isCaptain={isCaptain(p)}
                />;
    }

    const getDialogData = (): {possibleSubIn: ChangablePlayers[], possibleSubOut: ChangablePlayers[], oldFormation: Formation, newFormation: Formation} => {
        return {possibleSubIn:possibleSubIn, possibleSubOut: possibleSubOut, oldFormation: oldFormation!, newFormation: newFormation!};
    }

    const getCurrentFormation = (): Formation  => {
        return getFormationFrom(getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.DEFENDER).length, 
        getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.MIDFIELDER).length,
        getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.FORWARD).length);
    }

    const getTotalRoundPoints = (): number => {
        if (!props.roundSquad || !playerPoints || Object.values(EnumRoundStatus).indexOf(props.roundSquad.round.roundStatus) === EnumRoundStatus.COMING)
            return 0;
        else if ( Object.values(EnumRoundStatus).indexOf(props.roundSquad.round.roundStatus) === EnumRoundStatus.FINISHED && props.team && props.team.squads){
            return 0;
        }
        
        let p: number = playerPoints.filter(p=>( props.roundSquad!.starting11.map(i=>i.id).indexOf(p.id)>-1 )).reduce((sum, current) => sum + current.points, 0);
        
        if (props.roundSquad!.captain){
            let captain: PlayerPoints[] = playerPoints.filter(p=>( props.roundSquad!.captain.id === p.id ));
            if (captain && captain.length > 0)
                p += captain[0].points;
        }
        
        return p;
    }

    const handleFormationChange = (newFormation:Formation) => {
        let current: Formation = getCurrentFormation();
        let possibleSubIn: ChangablePlayers[] = getChangablePlayersIn(current, newFormation, squad!.starting11, squad!.players);
        let possibleSubOut: ChangablePlayers[] = getChangablePlayersOut(current, newFormation, squad!.starting11);

        setPossibleSubIn(possibleSubIn);
        setPossibleSubOut(possibleSubOut);
        setNewFormation(newFormation);
        setOpenSubInOut(true);
    }

    const handleRoundChange = (r: number): boolean => {
        if (noChanges){
            setRoundId(r);
            props.onRoundChanged(r);
            return true;
        }
        else{
            setAlertInfo({title: 'Izmene nisu sačuvane!', text: 'Potvrdite ili poništite izmene.'});
            setOpenAlertDialog(true);
            return false;
        }
    }

    const getNoSquadInfo = () => {
        if (!squad || !squad.round)
            return <div>učitavanje...</div>
        
        let round: RoundDto = getRoundById(roundId);
        if (!round)
            round = squad.round;
        if (!round)
            return <></>

        return <ThemeProvider theme={theme}>
            <Schedule initRound={squad!.round.id} onRoundChanged={handleRoundChange} roundPoints={roundPoints} points={0}/>
            {( Object.values(EnumRoundStatus).indexOf(round.roundStatus) === EnumRoundStatus.COMING) && <div>Idi na transfere, još nije kasno</div>}
            {( Object.values(EnumRoundStatus).indexOf(round.roundStatus) === EnumRoundStatus.IN_PROGRESS) && <div>Ova runda je počela, a niste podesili tim :(</div>}
            {( Object.values(EnumRoundStatus).indexOf(round.roundStatus) === EnumRoundStatus.FINISHED) && <div>Ova runda je završena, a niste podesili tim :(</div>}
        </ThemeProvider>
    }

    const getPointerEvents = () => {
        return  ( Object.values(EnumRoundStatus).indexOf(squad!.round.roundStatus) === EnumRoundStatus.COMING)?'auto':'none';
    }

    if (!allRounds)
        return <div>učitavanje rundi takmičenja...</div>
    if (!squad || roundId !== squad!.round.id)
        return getNoSquadInfo();
    return (
        <ThemeProvider theme={theme}>
            <Schedule initRound={squad!.round.id} onRoundChanged={handleRoundChange} roundPoints={roundPoints} points={getTotalRoundPoints()}/>
            {( Object.values(EnumRoundStatus).indexOf(squad!.round.roundStatus) !== EnumRoundStatus.COMING) && 
                <Stack direction="row" justifyContent="center" sx={{paddingTop:'15px'}}>
                    <Typography>Izmene nisu moguće!</Typography>
                </Stack>}
            <Stack direction="row" spacing={1} sx={{justifyContent:"center", alignItems:"center", paddingTop:'15px', pointerEvents:getPointerEvents()}}>
                <FormationChoose defaultFormation={oldFormation!} onFormationChange={handleFormationChange}/>
                <CaptainChoose defaultCaptain={squad!.captain} onCaptainChange={handleCaptainChange} starting11={squad!.starting11} />
                <Stack direction="column" spacing={0.5}>
                    <Button onClick={saveChanges}
                            color="primary" variant="contained"
                            disabled={noChanges}
                            style={{
                                backgroundColor: noChanges? "white": "blue",
                                opacity: noChanges? 0.8:1,
                            }}>
                        Potvrdi
                    </Button>

                    <Button onClick={cancelChanges}
                            color="inherit" variant="outlined"
                            disabled={noChanges}
                            style={{
                                borderColor: "red",
                                color: "red",
                                opacity: noChanges?0.2:1,
                            }}>
                        Poništi
                    </Button>
                </Stack>
            </Stack>
            
            {/* <DndProvider options={touchScreen ? TouchBackend : HTML5Backend}  key={counter} > */}
            <DndProvider backend={getDndBackend()}  key={counter} options={{                              
                transition: TouchTransition,
                skipDispatchOnTransition: true}}>
                <Paper sx={{width:'100%', pointerEvents:getPointerEvents()}}>
                    <Stack direction={'column'} 
                        sx={{ flexDirection: 'column-reverse',
                                alignItems: 'center',
                                backgroundImage: `url(${pitchImg})`,
                                backgroundRepeat: 'no-repeat', 
                                backgroundPosition: 'center',
                                backgroundSize: '100% 100%',
                            }}>
                        
                        <HelpIcon color="success" onClick={showHelp} style={{position:'relative', left:'40%', cursor:'hand', bottom:'90%'}}/>
                        
                        <Stack direction={'row'}>
                        {
                            getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.GOALKEEPER).map((p: PlayerDto) => (
                                getPlayer(p)
                            ))
                        }
                        </Stack>
                
                        <Stack direction={'row'}>
                        {
                            getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.DEFENDER).map((p) => (
                                getPlayer(p)  
                            ))
                        }
                        </Stack>
                
                        <Stack direction={'row'}>
                        {
                            getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.MIDFIELDER).map((p) => (
                                getPlayer(p)
                            ))
                        }
                        </Stack>
                
                        <Stack direction={'row'}>
                        {
                            getPlayersOnPosition(squad!.starting11, EnumPlayerPosition.FORWARD).map((p) => (
                                getPlayer(p)
                            ))
                        }
                
                        </Stack>
                    </Stack>
                    
                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center', justifyContent: 'center', border: '2px dashed black', background: '#267F00'}}>
                    {
                        getBench(squad!.players, squad!.starting11).map((p) => (
                            getPlayer(p)
                        ))
                    }
                    </Stack>
                </Paper>

            </DndProvider>
            {
             (oldFormation !== undefined && newFormation !== undefined) &&
             <SubInOutDialog 
                onDialogClosed={handleSubInOutDialogClosed} 
                onDialogConfirmed={handleSubInOutDialogConfirmed} 
                handleSelectedIn={handleSelectedIn}
                handleSelectedOut={handleSelectedOut}
                open={openSubInOut}
                data={getDialogData()}
                isValid={getIsValidSubIn()}/>
            }
            <AlertDialog open={openAlertDialog} onCloseDialog={() => {setOpenAlertDialog(false)}} info={ {title:alertInfo!.title, text: alertInfo!.text}}/>
        </ThemeProvider>
    );
}