
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Stack from '@mui/material/Stack';
import MatchDto from '../../../../model/match/MatchDto';
import { getCountyFlagAvatar } from '../../../../utils/CountryColors';
import PlayerMatchPointsDto from '../../../../model/playerpoints/PlayerMatchPointsDto';
import { getAction } from '../../../../utils/ActionType';
import Divider from '@mui/material/Divider';

interface StatsAccordionProps{
  match: MatchDto;
  live: boolean;
  stats: PlayerMatchPointsDto;
  points: number;
}


export default function StatsAccordion(props: StatsAccordionProps) {

  const getStyle = () => {
    return {color: props.live?'white':'grey'}
  }


    return ( 
      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{justifyContent:'center', display:'flex', backgroundColor: props.live? 'forestgreen':'lavender'}}
          >
          <Stack sx={{width:'100%', justifyContent:'center', display:'grid'}}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              {getCountyFlagAvatar(props.match.host.name, {maxWidth:'30px', maxHeight:'30px'})}
              <Typography variant='h5' sx={getStyle}>{props.match.homeScore}</Typography>
              <Typography variant='h5' sx={getStyle}>:</Typography>
              <Typography variant='h5' sx={getStyle()}>{props.match.visitorsScore}</Typography>
              {getCountyFlagAvatar(props.match.visitor.name, {maxWidth:'30px', maxHeight:'30px'})}
            </Stack>
          </Stack>
          </AccordionSummary>
          
          <AccordionDetails>
            <Stack direction="row" justifyContent={'space-between'}>
              <Typography variant="h6">Ukupno poena: </Typography>
              <Typography variant="h6">{props.points}</Typography>
            </Stack>
            <Divider />
            {props.stats.actions.map((s)=> (
              <Stack direction="row" justifyContent={'space-between'}>
                <Typography variant={"body2"}>{getAction(s.actionType).name +":"} </Typography>
                <Typography variant={"body2"}>{s.points} </Typography>
              </Stack>
            ))}
          </AccordionDetails>
      </Accordion>
    );
}