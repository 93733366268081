import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import CreateLeagueDto from "../../model/league/CreateLeagueDto";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";

interface LeagueControllerType {
  axiosGetLeagues: any;
  axiosGetGlobalLeague: any;
  axiosGetPrivateLeague: any;
  axiosJoinLeague: any;
  axiosCreateLeague: any;
}

export function LeagueController(): LeagueControllerType {
  const { authData } = useAppContext();

  const axiosGetLeagues = () => {
    return axios.get(Endpoints.LEAGUE + '/', axiosConfig(authData!.token));
  };

  const axiosGetGlobalLeague = () => {
    return axios.get(Endpoints.LEAGUE + '/global', axiosConfig(authData!.token));
  };

  const axiosGetPrivateLeague = (leagueId: number) => {
    return axios.get(Endpoints.LEAGUE + '/' + leagueId + '/get-private-league', axiosConfig(authData!.token));
  };
  
  const axiosJoinLeague = (code: String) => {
    return axios.post(Endpoints.LEAGUE + '/join-league-by-code/?code=' + code, {}, axiosConfig(authData!.token));
  };

  const axiosCreateLeague = (dto: CreateLeagueDto) => {
    return axios.post(Endpoints.LEAGUE + '/create-league', dto, axiosConfig(authData!.token));
  };

  return { axiosGetLeagues, axiosGetGlobalLeague, axiosGetPrivateLeague, axiosJoinLeague, axiosCreateLeague};
}

export type { LeagueControllerType};
