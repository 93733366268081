import { useState, useEffect } from "react";
import { useAppContext } from "../../context/AuthContext";
import { TeamController, TeamControllerType } from "../../controller/private/TeamController";
import PlayerDto from "../../model/player/PlayerDto";
import CreateTeamDto from "../../model/team/CreateTeamDto";
import TeamDto from "../../model/team/TeamDto";
import { handleErrorMessage } from "../../utils/Utils";
import { useNavigate } from "react-router";
import MessageType from "../../utils/MessageType";
import SaveSquadDto from "../../model/team/SaveSquadDto";
import { EVENT_ID } from "../../controller/private/EventController";

interface TeamServiceType {
  // getMyTeam: any;
  init: Function;
  team: TeamDto | undefined | null;
  createTeam: any;
  postRoundSquad: any;
  isLoading: boolean;
  saveSquad: Function;
  getTeam: Function;
  // setNewStarting11: Function;
  // roundSquad: RoundSquadDto | null;
  // changeRoundSquad: Function;
}

export default function TeamService(): TeamServiceType {
  const { showMessage, setBlockUI } = useAppContext();

  const { axiosGetMyTeam, axiosCreateTeam, axiosCreateRoundSquad, axiosSaveSquad }: TeamControllerType = TeamController();

  const [team, setTeam] = useState<TeamDto | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getMyTeam = (initial: boolean) => {
    setBlockUI(true);
    axiosGetMyTeam()
      .then((result: any) => {       
        setBlockUI(false);
        setIsLoading(false);
        setTeam(result.data);
        if (!initial)
          navigate("/");
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setBlockUI(false);
        setIsLoading(false);
      });
  }

  const init = () => {
    getMyTeam(true);
  }
  
  const createTeam = (data: CreateTeamDto) => {
    axiosCreateTeam(data)
      .then((result: any) => {
        let teamDto: TeamDto = result.data;
        setTeam(teamDto);
        showMessage('Tim je kreiran!', MessageType.SUCCESS);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      });
  };

  const saveSquad = (roundId: number, teamId: number, data: SaveSquadDto) => {
    axiosSaveSquad(EVENT_ID, roundId, teamId, data)
      .then((result: any) => {
        setTeam(result.data);
        showMessage('Promene su uspešno snimljene!', MessageType.SUCCESS);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      });
  };
  
  const postRoundSquad = (roundId: number, teamId: number, data: PlayerDto[]) => {
    axiosCreateRoundSquad(EVENT_ID, roundId, teamId, data)
      .then((result: any) => {
        if (result.data)
          getMyTeam(false);
        showMessage('Tim je uspešno snimljen!', MessageType.SUCCESS);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      });
  };
  
  // useEffect(() => {
  //   console.log('setting team', team);
  // },[team]);

  const getTeam=(): TeamDto => {
    // console.log(team!);
    return team!;
  }

  return { init, team, createTeam, postRoundSquad, isLoading, saveSquad, getTeam /*roundSquad, changeRoundSquad */};
}

export type { TeamServiceType };
