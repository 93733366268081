import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider } from '@mui/material';
import PlayerDto from "../../../model/player/PlayerDto";
import { getCountyFlagAvatar } from "../../../utils/CountryColors";
import { useMediaQuery } from 'react-responsive';

interface CaptainChooseProps{
  defaultCaptain: PlayerDto | null;
  starting11: PlayerDto[];
  onCaptainChange: Function;
}

export default function CaptainChoose(props: CaptainChooseProps) {

  const [captain, setCaptain] = useState(props.defaultCaptain);
  
  const isSmallMobile = useMediaQuery({ query: `(max-width: 550px)` });

  const handleChange = (event: SelectChangeEvent) => {
    let c: PlayerDto = props.starting11.filter((p: PlayerDto) => p.name === event.target.value)[0];
    setCaptain(c);
    props.onCaptainChange(c);
  };

  return (
      <FormControl sx={{ minWidth: '100px'}} size="medium">
        <InputLabel id="captain-label">Kapiten</InputLabel>
        <Select sx={{display: "flex", justifyContent:"space-between"}}
          id="captain-select"
          labelId="captain-label"
          label="Kapiten"
          value={captain?captain.name:''}
          defaultValue={captain?captain.name:''}
          onChange={handleChange}
        >
          {props.starting11.map((p: PlayerDto) => (
            <MenuItem sx={{justifyContent:"space-between"}} key={p.name} value={p.name}>
              {getCountyFlagAvatar(p.teamName)}
              {p.name} 
                {/* <Avatar variant={"rounded"} alt={'captain'} src={captainImg } /> */}
            </MenuItem>
          )
          )}
        </Select>
      </FormControl>
  );

}