import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Stack from '@mui/material/Stack';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Formation, { ChangablePlayers, getChangablePlayersForPosition, getFormationImg } from "../../../utils/Formation";
import SubInOutCard from "./SubInOutCard";
import EnumPlayerPosition from "../../../utils/enum/EnumPlayerPosition";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import PlayerDto from "../../../model/player/PlayerDto";

interface SubInOutDialogProps{
    onDialogClosed: Function;
    onDialogConfirmed: Function;
    data: {possibleSubIn: ChangablePlayers[], possibleSubOut: ChangablePlayers[], oldFormation: Formation, newFormation: Formation};
    open: boolean;
    handleSelectedIn: Function;
    handleSelectedOut: Function;
    isValid: boolean;
}

export default function SubInOutDialog(props: SubInOutDialogProps) {

const handleToClose = () => {
	props.onDialogClosed(true);
};

const handleConfirmed = () => {
	props.onDialogConfirmed();
};

const handleSelectedIn = (subIn: PlayerDto[], cp: ChangablePlayers) => {
    props.handleSelectedIn(subIn, cp);
}

const handleSelectedOut = (subOut: PlayerDto[], cp: ChangablePlayers) => {
    props.handleSelectedOut(subOut, cp);
}

if (!props.data)
    return <></>

if (!props.data.possibleSubIn || !props.data.possibleSubOut || props.data.possibleSubIn === undefined || props.data.possibleSubOut === undefined)
    return <></>

return (
        <Dialog open={props.open} onClose={handleToClose}>
            <DialogTitle>Taktičke promene</DialogTitle>
            <DialogContent>

            <Stack spacing={2} direction="column">
                <Stack direction="row" sx={{alignItems:"center", justifyContent:"center"}}>
                    <Stack direction="column">
                        {getFormationImg(props.data.oldFormation.name)}
                        {props.data.oldFormation.name}
                    </Stack>
                    <SwapHorizontalCircleIcon sx={{marginBottom:"20px"}} fontSize="large"/>
                    <Stack direction="column">
                        {getFormationImg(props.data.newFormation.name)}
                        {props.data.newFormation.name}
                    </Stack>
                </Stack>
                <DialogContentText>
                    Izvršite izmene da biste prešli na novu formaciju.
                </DialogContentText>    
                <SubInOutCard 
                    title={'Odbrana'}
                    subIn={getChangablePlayersForPosition(props.data.possibleSubIn, EnumPlayerPosition.DEFENDER)}
                    subOut={getChangablePlayersForPosition(props.data.possibleSubOut, EnumPlayerPosition.DEFENDER)}
                    setPropSelectedIn={handleSelectedIn}
                    setPropSelectedOut={handleSelectedOut}
                />
                
                <SubInOutCard 
                    title={'Sredina'}
                    subIn={getChangablePlayersForPosition(props.data.possibleSubIn, EnumPlayerPosition.MIDFIELDER)}
                    subOut={getChangablePlayersForPosition(props.data.possibleSubOut, EnumPlayerPosition.MIDFIELDER)}
                    setPropSelectedIn={handleSelectedIn}
                    setPropSelectedOut={handleSelectedOut}
                />

                <SubInOutCard 
                    title={'Napad'}
                    subIn={getChangablePlayersForPosition(props.data.possibleSubIn, EnumPlayerPosition.FORWARD)}
                    subOut={getChangablePlayersForPosition(props.data.possibleSubOut, EnumPlayerPosition.FORWARD)}
                    setPropSelectedIn={handleSelectedIn}
                    setPropSelectedOut={handleSelectedOut}
                />
            </Stack>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleConfirmed} disabled={!props.isValid}
                    color="primary" variant="contained" autoFocus
                    style={{
                        backgroundColor: !props.isValid?"grey":"blue"       
                    }}>
                Potvrdi
            </Button>
            
            <Button onClick={handleToClose}
                    color="secondary"
                    style={{
                        color: "red"       
                    }}>
                Odustani
            </Button>
            </DialogActions>
        </Dialog>
);
}
