import IntroImage from "./IntroImage";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

const theme = createTheme();

export default function Registered() {

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
            <Box
                sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

                }}
            >
                <IntroImage/>
                <Typography variant="h6">Hvala što ste se registrovali!</Typography>
                <Typography variant="h6">Uskoro ćete dobiti verifikacioni mejl.</Typography>
                <Grid item>
                    <Link href="/" variant="body2">
                        Već si verifikovao? Uloguj se.
                    </Link>
                </Grid>

            </Box>
        </Container>
        </ThemeProvider>
    );
}