import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InputLabel } from "@material-ui/core";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import LeagueDto from "../../../model/league/LeagueDto";
import LeagueService, { LeagueServiceType } from "../../../service/league/LeagueService";
import { useEffectOnce } from "../../../utils/Utils";
import LeagueRankingDto from '../../../model/league/LeagueRankingDto';
import League from './League';
import JoinLeagueDialog from './JoinLeagueDialog';
import { ThemeProvider, createTheme } from '@material-ui/core';
import CreateLeagueDialog from './CreateLeagueDialog';
import CreateLeagueDto from '../../../model/league/CreateLeagueDto';

export default function Leagues() {
  
    const theme = createTheme({
      palette: {
        primary: {
          main: '#1976d2',
        },
      },
    });

    const [league, setLeague] = useState<LeagueDto | undefined>(undefined);
    const [leagues, setLeagues] = useState<LeagueDto[]>([]);
    const [activeLeague, setActiveLeague] = useState<LeagueRankingDto | undefined>(undefined)
    const [joinLeagueDialog, setOpenJoinLeagueDialog] = useState<boolean>(false);
    const [createLeagueDialog, setCreateLeagueDialog] = useState<boolean>(false);

    const { getLeagues, getLeague, requestJoinLeague, requestCreateLeague} : LeagueServiceType = LeagueService();

    const handleChange = (event: SelectChangeEvent) => {
      if (leagues.length === 0)
        return;
      let le: LeagueDto = leagues.filter((l: LeagueDto) => l.name === event.target.value)[0];
      handleLeagueChange(le);
    };

    useEffectOnce(() => {
        getLeagues(onLeaguesReceived);
    });

    const onLeaguesReceived = (list: LeagueDto[]) => {
      setLeagues(list);
      if (list.length > 0){
        setLeague(list[0]);
        handleLeagueChange(list[0]);
      }
    }

    const onLeagueReceived = (l: LeagueRankingDto) => {
      console.log(l);
      setActiveLeague(l);
    }

    const handleLeagueChange = (l: LeagueDto) => {
      setLeague(l);
      console.log(l);
      getLeague(l.id, onLeagueReceived);
    }

    const handleJoinLeague = (code: String) => {
      requestJoinLeague(code);
    }

    const handleCreateLeague = (name: String, desc: String) => {
      let dto: CreateLeagueDto = {name:name, description: desc};
      console.log(dto);
      requestCreateLeague(dto);
    }

    const handleCloseJoinLeague = () => {
      setOpenJoinLeagueDialog(false);
    }

    const openJoinLeagueDialog = () => {
      setOpenJoinLeagueDialog(true);
    }
    
    const openCreateLeagueDialog = () => {
      setCreateLeagueDialog(true);
    }
    
    const handleCloseCreateLeague = () => {
      setCreateLeagueDialog(false);
    }

    if (league === undefined || leagues.length === 0)
      return <div>nema podataka</div>;

    return (
      
      <ThemeProvider theme={theme}>
        <Stack justifyContent="center" sx={{width:'100%', display:'inline-grid'}}>
            <Stack direction ="row" justifyContent="center" >
              
              <FormControl sx={{ m: 1, minWidth: 150, width:'fit-content'}} size="medium">
              <InputLabel id="league-label">Liga</InputLabel>
              <Select sx={{display: "flex", justifyContent:"space-between"}}
                id="league-select"
                labelId="league-label"
                label="Liga"
                value={league.name}
                onChange={handleChange}
              >
                {leagues.map((l: LeagueDto) => (
                  <MenuItem sx={{justifyContent:"space-between"}} key={l.id} value={l.name}>
                    {l.name}
                  </MenuItem>
                )
                )}
              </Select>
            </FormControl>
          </Stack>
          {league !== undefined && <Typography >Opis: <b>{league.description}</b></Typography> }
          {league !== undefined && league.id > 0 && <Typography>Pošaljite prijateljima pristupni kod za ovu ligu: <b>{league.code}</b></Typography>}
          <Typography sx={{padding:'10px 0px'}}>Imaš kod da se pridružiš još nekoj ligi? <i style={{cursor:'pointer'}} onClick={openJoinLeagueDialog}><u>pridruži se</u></i> ili <i style={{cursor:'pointer'}} onClick={openCreateLeagueDialog}><u>kreiraj ligu</u></i>.</Typography>
          {activeLeague !== undefined && <League leagueRankings={activeLeague}/>}
          <JoinLeagueDialog open={joinLeagueDialog} onDialogClosed={handleCloseJoinLeague} onJoinLeague={handleJoinLeague}/>
          <CreateLeagueDialog open={createLeagueDialog} onDialogClosed={handleCloseCreateLeague} onCreateLeague={handleCreateLeague}/>
        </Stack>
      </ThemeProvider>
      );
    }