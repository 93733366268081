import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import PlayerDto from '../../model/player/PlayerDto';
import EnumPlayerPosition from '../../utils/enum/EnumPlayerPosition';
import { actions, ActionType } from '../../utils/ActionType';

interface AdminPlayerProps {
    player: PlayerDto;
    onSendAction: Function;
}

export default function AdminPlayer (props: AdminPlayerProps) {

    
    const [action, setAction] = useState<ActionType>();

    const sendAction = () => {
        if (action !== undefined){
            props.onSendAction(action, props.player);
            setAction(undefined);
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
          } = event;

        let a: ActionType = actions.filter(aa => aa.name === value)[0];
        setAction(a)
      };

    return (
        <Stack  direction={"row"} sx={{width:'90%', justifyContent:'space-between'}}>
        <Typography variant='body2'>{Object.values(EnumPlayerPosition)[Object.keys(EnumPlayerPosition).indexOf(props.player.playerPosition)]}</Typography>
        <Typography variant='body2'>{props.player.name}</Typography>
        
        <FormControl sx={{ minWidth: '100px'}} size="small">
            {/* <InputLabel id="action-label">Akcija</InputLabel> */}
            <Select sx={{display: "flex", justifyContent:"space-between"}}
            id="action-select"
            // labelId="action-label"
            value={action?.name}
            // label="Akcija"
            onChange={handleChange}
            >
            {actions.map((a: ActionType) => (
                <MenuItem sx={{justifyContent:"space-between"}} key={a.name} value={a.name}>
                {a.name + "(" + a.points + ")"}
                </MenuItem>
            )
            )}
            </Select>
        </FormControl>
        <Button variant='outlined' onClick={sendAction}>
            Pošalji
        </Button>
    </Stack>
    )
}