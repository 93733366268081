import { makeStyles } from "@material-ui/core/styles";
// const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex-root",
    // width: '100vw',
  },
  drawer: {
    // width: '100vw',
    flexShrink: 0,
    zIndex: 0,
  },
  drawerPaper: {
    // width: '100vw',
    background: "#fafafa",
    marginTop: theme.spacing(1),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    // width: '100vw',
  },
  
}));

export default useStyles;
