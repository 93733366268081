import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

interface ConfirmTransferDialogProps{
    onDialogClosed: Function;
    onDialogConfirmed: Function;
    info: {title: string, text: string, open: boolean, additional: any | null};
}

export default function ConfirmTransferDialog(props: ConfirmTransferDialogProps) {

const handleClose = () => {
    props.onDialogClosed(true);
};

    
const handleConfirm = () => {
	props.onDialogConfirmed(true);
};

return (
	<div>

        <Dialog open={props.info.open} onClose={handleClose}>
            <DialogTitle>{props.info.title}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {props.info.text}
            </DialogContentText>
            {props.info.additional && props.info.additional}
            </DialogContent>
            <DialogActions>
                
            <Button onClick={handleConfirm}
                    color="primary"
                    variant="contained">
                Potvrdi
            </Button>
            <Button onClick={handleClose}
                    color="secondary" autoFocus>
                Odustani
            </Button>
            </DialogActions>
        </Dialog>
	</div>
);
}
