
import { Route, Routes, Router } from "react-router-dom";
import useStyles from "./PrivateRouteStyle";
import AppHeader from "./../header/AppHeader"
import Team from "./private/team/Team";
import Leagues from "./private/league/Leagues";
import Transfers2 from "./private/transfers/Transfers2";
import TeamService, { TeamServiceType } from "../service/team/TeamService";
import { useEffectOnce } from "../utils/Utils";
import { useState } from "react";
import About from "./private/about/About";
import Rules from "./private/rules/Rules";
import AdminPanel from "./admin/AdminPanel";


export default function PrivateRoute() {
  const classes = useStyles();

  const { init, team, isLoading, saveSquad, createTeam, postRoundSquad  }: TeamServiceType = TeamService();

  useEffectOnce(() => {
    init();
  });

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  // useEffect(()=>{console.log ('private route, team changed', team)},[team])

  return (
      <div className={classes.root}>
        <AppHeader />
        <main className={classes.content}>
            <Routes>
            <Route path="/" element={<Team saveSquad= {saveSquad} team={team} createTeam={createTeam} isLoading={isLoading}/>}/>
            <Route path="/league" element={<Leagues/>} />
            <Route path="/transfers" element={<Transfers2 postRoundSquad={postRoundSquad} team={team}/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/rules" element={<Rules/>} />
            <Route path="/admin" element={<AdminPanel/>} />
          </Routes>
        </main>
      </div>
  );
}
