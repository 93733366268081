import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IntroImage from './IntroImage';
import PublicService, { PublicServiceType } from '../../service/public/PublicService';

import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useState} from 'react';

function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://aparteko.com">
          Aparteko
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const theme = createTheme();

export default function SignUp() {

  const navigate = useNavigate();  
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { register }: PublicServiceType = PublicService();
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  // const [valid, setValid] = useState<boolean>(false);
  const expression: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [socData, setSocData] = useState<any>({name: '', email: '', imageUrl: ''});

  const responseGoogle = (response: any) => {
    console.log(response);
    setSocData({
      name: response.profileObj.name,
      email: response.profileObj.email,
      imageUrl: response.profileObj.imageUrl
    });
  };



  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    if (passwordErrorText !== '' || emailErrorText !== '')
      return;

    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    register({lastName: data.get('lastName'), firstName: data.get('firstName'), email: data.get('email'), password: data.get('password')}, null);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  function onEmailChange(value: string): void {
    if (!expression.test(value)){
      setEmailErrorText('Emejl format nije ispravan');
    }
    else 
      setEmailErrorText('');
  }

  const validate = (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    console.log(data.get('password')!.valueOf());
    console.log(data.get('password'));
    
    let passErr: string = '';
    if (data.get('password') !== data.get('passwordRepeated')){
      passErr = 'šifra sa ponovljenom šifrom mora da se poklapa';
    }
    else if ((data.get('password') as string).length < 8){
      passErr = 'minimum 8 karaktera';
    }
    setPasswordErrorText(passErr);

  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IntroImage/>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registruj se
          </Typography>

          {/* <GoogleLogin
            disabled={false}
            clientId="762878941498-soe6j4p8c41sovr9qc6oa86ha93bp4t4.apps.googleusercontent.com"
            buttonText="Google registracija"
            className="mt-3 mb-3"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}

          <Box component="form" noValidate onChange={validate} onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Ime"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Prezime"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Emejl adresa"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => onEmailChange(e.target.value)}
                  error={emailErrorText !== ''}
                  helperText={emailErrorText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Šifra"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  error={passwordErrorText !== ''}
                  helperText={passwordErrorText}

                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordRepeated"
                  label="Ponovi šifru"
                  type={showPassword ? "text" : "password"}
                  id="passwordRepeated"

                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Registruj se
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Zaboravljena šifra?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={()=>{navigate('/')}} variant="body2">
                  {"Imaš nalog? Prijavi se"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

