import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Store from './utils/Store';
import { SnackbarProvider } from 'notistack';
import BlockUI from './system/block-ui/BlockUI';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './utils/ErrorFallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SnackbarProvider maxSnack={3}>
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Store>
          <ErrorBoundary 
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <App />
          </ErrorBoundary>
          <BlockUI />
        </Store>
      </BrowserRouter>
    </React.StrictMode>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
