import { useAppContext } from "../../context/AuthContext";
import { AdminController, AdminControllerType } from "../../controller/admin/AdminController";
import UpdateMatchDto from "../../model/match/UpdateMatchDto";
import UpdateMatchPeriodDto from "../../model/match/UpdateMatchPeriodDto";
import CreatePlayerActionDto from "../../model/playerpoints/CreatePlayerActionDto";
import PlayerActionDto from "../../model/playerpoints/PlayerActionDto";
import MessageType from "../../utils/MessageType";

import { handleErrorMessage } from "../../utils/Utils";
import EnumMatchPeriod from "../../utils/enum/EnumMatchPeriod";

interface AdminServiceType { 
  saveMatch: Function,
  getLiveMatches: Function;
  getCurrentRoundMatches: Function;
  startMatch: Function;
  changeMatchPeriod: Function;
  endMatch: Function;
  getSportTeamPlayers: Function;
  postPlayerAction: Function;
  getAllPlayerActions: Function;
  postRemoveAction: Function;
  startRound: Function;
  stopRound: Function;
}

export default function AdminService(): AdminServiceType {
  const { showMessage, setBlockUI } = useAppContext();
  const { 
    axiosSaveMatch, 
    axiosGetLiveMatches, 
    axiosGetCurrentRoundMatches,
    axiosStartMatch, 
    axiosChangeMatchPeriod,
    axiosEndMatch, 
    axiosGetSportTeamPlayers, 
    axiosPostPlayerAction, 
    axiosGetAllPlayerActions,
    axiosPostRemoveAction,
    axiosStartRound,
    axiosStopRound
  } : AdminControllerType = AdminController();
  

  const getLiveMatches = (onLiveMatchesRetrieved: Function) => {
    axiosGetLiveMatches()
    .then((result: any) => {
      setBlockUI(true);
      onLiveMatchesRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };
  

  const getCurrentRoundMatches = (onCurrentRoundMatchesRetrieved: Function) => {
    axiosGetCurrentRoundMatches()
    .then((result: any) => {
      setBlockUI(true);
      onCurrentRoundMatchesRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };

  const getSportTeamPlayers = (sportTeamId: number, onSportTeamPlayersRetrieved: Function) => {
    axiosGetSportTeamPlayers(sportTeamId)
    .then((result: any) => {
      setBlockUI(true);
      onSportTeamPlayersRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };

  const saveMatch = (m: UpdateMatchDto, onLiveMatchesRetrieved: Function) => {
    axiosSaveMatch(m)
    .then((result: any) => {
      setBlockUI(true);
      onLiveMatchesRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };

  const startMatch = (id: number, onLiveMatchesRetrieved: Function) => {
    axiosStartMatch(id)
    .then((result: any) => {
      setBlockUI(true);
      onLiveMatchesRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };
  
  const changeMatchPeriod = (id: number, ump: UpdateMatchPeriodDto) => {
    axiosChangeMatchPeriod(id, ump)
    .then((result: any) => {
      setBlockUI(true);
      showMessage('Uspešno promenjen period utakmice!', MessageType.SUCCESS);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };

  const endMatch = (id: number, onLiveMatchesRetrieved: Function) => {
    axiosEndMatch(id)
    .then((result: any) => {
      setBlockUI(true);
      onLiveMatchesRetrieved(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  };

  const postPlayerAction = (dto: CreatePlayerActionDto) => {
    axiosPostPlayerAction(dto)
    .then((result: any) => {
      setBlockUI(true);
      showMessage ('Uspešno zabeleženo!', MessageType.SUCCESS);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  }

  const getAllPlayerActions = (matchId: number, onPlayerActionsRetreived: Function ) => {
    axiosGetAllPlayerActions(matchId)
    .then((result: any) => {
      setBlockUI(true);
      onPlayerActionsRetreived(result.data)
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  }

  const startRound = (roundId: number, onRoundStarted: Function ) => {
    console.log('start round: ' + roundId);
    axiosStartRound(roundId)
    .then((result: any) => {
      setBlockUI(true);
      onRoundStarted(result.data)
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  }
  
  const stopRound = (roundId: number, onRoundStopped: Function ) => {
    console.log('stop round: ' + roundId);
    axiosStopRound(roundId)
    .then((result: any) => {
      setBlockUI(true);
      onRoundStopped(result.data)
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  }

  const postRemoveAction = (actionId: number ) => {
    axiosPostRemoveAction(actionId)
    .then((result: any) => {
      setBlockUI(true);
      showMessage ('Uspešno zabeleženo!', MessageType.SUCCESS);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    })
    .finally(() => {
      setBlockUI(false);
    }); 
  }

  return { saveMatch, getLiveMatches, getCurrentRoundMatches, startMatch, changeMatchPeriod, endMatch, getSportTeamPlayers, postPlayerAction, getAllPlayerActions, postRemoveAction, startRound, stopRound };
}

export type { AdminServiceType };