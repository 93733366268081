import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

export const tableIcons: any = {
  Add: forwardRef((props, ref): any => <AddBox />),
  Check: forwardRef((props, ref): any => <Check />),
  Clear: forwardRef((props, ref): any => <Clear />),
  Delete: forwardRef((props, ref): any => <DeleteOutline />),
  DetailPanel: forwardRef((props, ref): any => <ChevronRight />),
  Edit: forwardRef((props, ref): any => <Edit />),
  Export: forwardRef((props, ref): any => <SaveAlt />),
  Filter: forwardRef((props, ref): any => <FilterList />),
  FirstPage: forwardRef((props, ref): any => <FirstPage />),
  LastPage: forwardRef((props, ref): any => <LastPage />),
  NextPage: forwardRef((props, ref): any => <ChevronRight />),
  PreviousPage: forwardRef((props, ref): any => <ChevronLeft />),
  ResetSearch: forwardRef((props, ref): any => <Clear />),
  Search: forwardRef((props, ref): any => <Search />),
  SortArrow: forwardRef((props, ref): any => <ArrowDownward />),
  ThirdStateCheck: forwardRef((props, ref): any => <Remove />),
  ViewColumn: forwardRef((props, ref): any => <ViewColumn />),
};

export const localization = {
  body: {
    addTooltip: "Dodaj",
    deleteTooltip: "Obriši",
    emptyDataSourceMessage: "Nema podataka",
    editTooltip: "Izmeni",
    filterRow: {
      filterTooltip: "Filter",
    },
    editRow: {
      deleteText: "Da li ste sigurni da želite da obrišete?",
      cancelTooltip: "Odustani",
      saveTooltip: "Potvrdi",
    },
  },
  toolbar: {
    searchTooltip: "Pretraga",
    searchPlaceholder: "Pretraga",
  },
  pagination: {
    labelRowsSelect: "redova",
    labelDisplayedRows: "{from}-{to} od {count}",
    firstTooltip: "Prva strana",
    previousTooltip: "Prethodna strana",
    nextTooltip: "Sledeća strana",
    lastTooltip: "Poslednja strana",
  },
  header: {
    actions: "Akcije",
  },
};
