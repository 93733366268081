import { createContext, useContext } from "react";
import AuthData from "../model/AuthData";

interface AppContextProps {
  authData: AuthData | undefined;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  showMessage: (summery: string, detail: string, severity?: string) => void;
  blockUI: boolean;
  setBlockUI: any;

  // t: (scope: any, options?: any) => string;
  // locale: string;
  // setLocale: any;
  // typeList: any;
}

export const AppContext = createContext({} as AppContextProps);

export function useAppContext() {
  let uc = useContext(AppContext);
  return uc;
}

export const headers = (token: string, params: any) => {
  return {
    headers: {
      "Content-Type": "application/json;",
      Authorization: "Bearer " + token,
    },
    params: params ? params : {},
  };
};

export const headersDelete = (token: String, data: any) => {
  return {
    headers: {
      "Content-Type": "application/json;",
      Authorization: "Bearer " + token,
    },
    data: data ? data : {},
  };
};
