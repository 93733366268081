import { useState } from "react";
import { useAppContext } from "../../context/AuthContext";
import { handleErrorMessage, useEffectOnce } from "../../utils/Utils";
import RoundDto from "../../model/event/RoundDto";
import { EventController, EventControllerType } from "../../controller/private/EventController";
import RoundShortDto from "../../model/event/RoundShortDto";
import MatchDto from "../../model/match/MatchDto";

interface EventServiceType { 
  nextRound: RoundDto | null,
  allRounds: RoundDto[] | null,
  allRoundsShort: RoundShortDto[] | null,
  refreshAllRounds: any;
  requestMatchesForRound: any;
}

export default function EventService(): EventServiceType {
  const { showMessage, setBlockUI } = useAppContext();
  const { axiosGetNextRound, axiosGetAllRounds, axiosGetAllRoundsShort, axiosGetMatchesForRound }: EventControllerType = EventController();
  const [nextRound, setNextRound] = useState<RoundDto | null>(null); 
  const [allRounds, setAllRounds] = useState<RoundDto[] | null>(null);  
  const [allRoundsShort, setAllRoundsShort] = useState<RoundShortDto[] | null>(null);
  const [roundMatchesMap, setRoundMatchesMap] = useState<Map<number, MatchDto[]>>(new Map()); 

  useEffectOnce(() => {
    axiosGetNextRound()
      .then((result: any) => {
        setBlockUI(true);
        setNextRound(result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      })
      .finally(()=>{setBlockUI(false);});
   });
    
   useEffectOnce(() => {
    refreshAllRounds();
  });  

  useEffectOnce(() => {
    axiosGetAllRoundsShort()
    .then((result: any) => {
      setAllRoundsShort(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    });
  });

  const requestMatchesForRound = (round: number, callback: Function) =>{
    if (roundMatchesMap.get(round)){
      callback(round, roundMatchesMap.get(round));
      return;
    }
    axiosGetMatchesForRound(round)
      .then((result: any) => {
        setRoundMatchesMap(new Map(roundMatchesMap.set(round, result.data)));
        // setNextRound(result.data);
        callback(round, result.data);
      })
      .catch((error: any) => {
        handleErrorMessage(showMessage, error);
      });
    return []; 
   };

   const refreshAllRounds = () => {
    axiosGetAllRounds()
    .then((result: any) => {
      setAllRounds(result.data);
    })
    .catch((error: any) => {
      handleErrorMessage(showMessage, error);
    });
  }

  return { nextRound, allRounds, allRoundsShort, refreshAllRounds, requestMatchesForRound};
}


export type { EventServiceType };


