import NewTeam from "./NewTeam";
import Starting11 from "./Starting11";
import TeamDto from "../../../model/team/TeamDto";
import RoundSquadDto from "../../../model/team/RoundSquadDto";
import SaveSquadDto from "../../../model/team/SaveSquadDto";
import EventService, { EventServiceType } from "../../../service/event/EventService";
import EnumRoundStatus from "../../../utils/enum/EnumRoundStatus";
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

interface TeamProps{
  isLoading: boolean;
  saveSquad: Function;
  createTeam: Function;
  team: TeamDto | null | undefined;
  
}

export default function Team(props: TeamProps) {

  const { nextRound, allRounds }: EventServiceType = EventService();
  const [activeRound, setActiveRound] = useState<RoundSquadDto | null>();
  const [roundId, setRoundId] = useState<number>(-1);
  const navigate = useNavigate();

  const handleSaveChanges = (rs: RoundSquadDto, roundId: number) => {
    let s: SaveSquadDto = {starting11: rs.starting11.map(p=>p.id), captain: rs.captain.id};
    props.saveSquad(roundId, rs.team.id, s)
  }

  const getFirstRoundSquadByStatus = (status: EnumRoundStatus) : RoundSquadDto | null=> {

    let list: RoundSquadDto[] = []
    list = props.team!.squads.filter(rs =>  Object.values(EnumRoundStatus).indexOf(rs.round.roundStatus) === status && rs.players.length > 0).sort((p, p2) => {return p2.round.id - p.round.id });
    return (list.length > 0)? list[list.length - 1]: null;
  }

  const getRoundSquadById = (roundId: number) : RoundSquadDto | null=> {

    let list: RoundSquadDto[] = []
    list = props.team!.squads.filter(rs => rs.round.id === roundId);
    return (list.length > 0)? list[list.length - 1]: null;
  }

  const getFirstRoundSquad = (): RoundSquadDto | null=> {
 
    if (!props.team || !props.team!.squads)
      return null;

    let active = getFirstRoundSquadByStatus(EnumRoundStatus.IN_PROGRESS);
    if (active !== null)
      return active;

    let coming = getFirstRoundSquadByStatus(EnumRoundStatus.COMING);
    if (coming !== null)
      return coming;
        
    let finished = getFirstRoundSquadByStatus(EnumRoundStatus.FINISHED);
    if (finished !== null)
      return finished;
  
    return null;
  }

  const handleRoundChange = (roundId: number) =>{
    let ar = getRoundSquadById(roundId);
    setRoundId(roundId);
    setActiveRound(ar);
  }

  useEffect(() => {
    let ar: RoundSquadDto | null = getFirstRoundSquad();

    if (!props.team)
      return;

    if (!ar){
      return;
    }
    setRoundId(ar.round.id);
    setActiveRound(ar); 
  },[props.team]);

  useEffect(() => {
  },[activeRound]);  

  const handleCreateTeam = (data: any) => {
    props.createTeam(data);
  }

  if (!props.team){
      return <div>
          <NewTeam team={props.team} onCreateTeam={handleCreateTeam}/>
        </div>
  }
  else if (!props.team.squads || !props.team.squads || props.team!.squads.length === 0){
      navigate("/transfers");
  }
  if (!activeRound && props.team! && props.team!.squads && props.team!.squads.length === 0){
      // return <Transfers2 team={props.team} />
      <div> učitavanje...</div>
  }

  return <Starting11 team={props.team} roundSquad={ activeRound} onSaveChanges={handleSaveChanges} onRoundChanged={handleRoundChange}/> 

}