import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import RoundShortDto from "../../../model/event/RoundShortDto";
import {useState} from "react";
import EnumRoundStatus from '../../../utils/enum/EnumRoundStatus';
import { getDateAndTimeAsString } from '../../../utils/Utils';


interface RoundCardProps{
    round: RoundShortDto;
    onClick: Function;
    isSelected: boolean;
    livePoints?: number;
    finishedPoints?: number;
}

export default function RoundCard(props: RoundCardProps) {  
  
  const [ startDate] = useState<Date>(new Date (props.round.startDate));

  const getStartTime = (): string => {
  
    if (isFinished())
      return 'završena';
    else if (isLive())
      return 'u toku';

    return getDateAndTimeAsString(startDate);
  }
  
  const getLivePoints = () => {
    if (props.livePoints === undefined)
      return  <Typography>u toku</Typography> 
    return <Typography>{props.livePoints + ' p'}</Typography>
  }

  const getFinishedPoints = () => {
    if (props.finishedPoints === undefined)
      return  <Typography>završeno</Typography> 
    return <Typography>{props.finishedPoints + ' p'}</Typography>
  }

  const handleRoundClick = () => {
    props.onClick(props.round.id);
  }

  const getStyle = () => {
    let common = {display: 'inline-table', padding:'5px', textAlign:'center', cursor:'pointer', boxShadow: '1px 1px 3px 3px #ccc'};
    let specific = {}
    if (isFinished())
      specific = {backgroundColor: 'forestgreen', color: 'white'};
    else if (isLive())
      specific = {backgroundColor: 'darkseagreen', color: 'white', fontWeight: 'bolder'};
    else
      specific = {backgroundColor: 'lavender'};

    if (props.isSelected)
      return  {...common, ...specific, ...{boxShadow: '0 7px 2px -2px limegreen'}} 
    return {...common, ...specific};
  }

  const isLive = (): boolean => {
    return props.round.roundStatus + "" === Object.keys(EnumRoundStatus)[Object.values(EnumRoundStatus).indexOf(EnumRoundStatus.IN_PROGRESS)];
  }

  const isComing = (): boolean => {
    return props.round.roundStatus + "" === Object.keys(EnumRoundStatus)[Object.values(EnumRoundStatus).indexOf(EnumRoundStatus.COMING)];
  }

  const isFinished = (): boolean => {
    return props.round.roundStatus + "" === Object.keys(EnumRoundStatus)[Object.values(EnumRoundStatus).indexOf(EnumRoundStatus.FINISHED)];
  }

  return (
  <Card sx={getStyle()} onClick={handleRoundClick}>
    <Typography variant='subtitle1' noWrap>
        {props.round.name}
    </Typography>
    <Typography variant='caption' noWrap>
    { isComing() && getStartTime() }
    { isLive() && getLivePoints() }
    { isFinished() && getFinishedPoints() }
    </Typography>

  </Card>
  );
}