import { useAppContext } from "../../context/AuthContext";
import { PublicController, PublicControllerType } from "../../controller/public/PublicController";
import MessageType from "../../utils/MessageType";
import { handleErrorMessage } from "../../utils/Utils";
import { useNavigate } from "react-router";

interface PublicServiceType {
  login: any;
  register: any;
}

export default function PublicService(): PublicServiceType {
  
  const { showMessage, updateAuthData } = useAppContext();

  const { axiosLogin, axiosRegister }: PublicControllerType = PublicController();
  const navigate = useNavigate();

  const login = (values: any, actions: any) => {
    axiosLogin(values)
      .then((result: any) => {
        updateAuthData(result.data);
      })
      .catch((error: any) => {
        
        console.log(error);
        handleErrorMessage(showMessage, error);
      });
      if (actions)
        actions.setSubmitting(false);
  };


  const register = (values: any, actions: any) => {
    axiosRegister(values)
      .then((result: any) => {
        // updateAuthData(result.data);
        showMessage('Uspešna registracija! Uloguj se.', MessageType.SUCCESS);
        navigate('/');
      })
      .catch((error: any) => {

        console.log(error);
        handleErrorMessage(showMessage, error);
      });
      if (actions)
        actions.setSubmitting(false);
  };

  return { login, register};
}

export type { PublicServiceType };
