import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";
import { EVENT_ID } from "./EventController";

interface PlayerPointsControllerType {
  axiosGetPlayersPoints: Function;
  axiosGetPlayerPoints: Function;
  axiosGetMatchActionsPerPlayer: Function;
  axiosGetAllPlayerActions: Function;
}

export function PlayerPointsController(): PlayerPointsControllerType {
  const { authData } = useAppContext();

  const axiosGetPlayersPoints = (roundId:number) => {
    return axios.get(`${Endpoints.PLAYER_ACTION}/${EVENT_ID}/${roundId}/get-players-points`, axiosConfig(authData!.token));
  };
  
  
  const axiosGetPlayerPoints = (playerId:number) => {
    return axios.get(`${Endpoints.PLAYER_ACTION}/${EVENT_ID}/${playerId}/get-player-points`, axiosConfig(authData!.token));
  };

  
  const axiosGetMatchActionsPerPlayer = (matchId: number) => {
    return axios.get(`${Endpoints.PLAYER_ACTION}/${matchId}/get-actions-per-player`, axiosConfig(authData!.token));
  };

  const axiosGetAllPlayerActions = (matchId: number) => {
    return axios.get(Endpoints.PLAYER_ACTION + '/' + matchId + '/get-all-actions', axiosConfig(authData!.token));
  };

  return { axiosGetPlayersPoints, axiosGetPlayerPoints, axiosGetMatchActionsPerPlayer, axiosGetAllPlayerActions};
}

export type { PlayerPointsControllerType};
