import axios from "axios";
import LoginDto from "../../model/login/LoginDto";
import Endpoints from "../../utils/Endpoints";

interface PublicControllerType {
  axiosLogin: any,
  axiosRegister: any;
}

export function PublicController(): PublicControllerType {
  const axiosLogin = (data: LoginDto) => {
    return axios.post(Endpoints.AUTHENTICATE, data);
  };

  const axiosRegister = (user: any) => {
    return axios.post(Endpoints.REGISTRATION, user);
  };


  return { axiosLogin, axiosRegister };
}

export type { PublicControllerType };
