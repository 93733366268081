import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


interface TransferProgressProps{
    value: number;
    maxPlayers:number
}

export default function TransferProgress(props: TransferProgressProps) {

    const getValue = ():number => {
        if (props.value > props.maxPlayers)
        {
            return 100;
        }
        return props.value * 100 / props.maxPlayers;
    }

    const getProgressStyle = () => {
        // return {};
        return { color : ((props.value > props.maxPlayers)?'red':'blue') };
    }

  return (
    <Box sx={{ position: 'relative' }}>
     <CircularProgress
        variant="determinate"
        sx={{
            position: 'absolute',
            color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />

      <CircularProgress variant="determinate" value={getValue()} style={getProgressStyle()} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.value + "/" + props.maxPlayers}</Typography>
      </Box>
    </Box>
  );
}

// export default function CircularStatic(props: CircularStaticProps) {
//  const [progress, setProgress] = React.useState(10);
//   return <TransferProgress value={progress} maxPlayers={props.maxPlayers} />;
// }
