import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LeagueRankingDto from "../../../model/league/LeagueRankingDto";
import RoundRankingDto from '../../../model/league/RoundRankingDto';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useState, useEffect} from 'react';
import TeamPointsDto from '../../../model/league/TeamPointsDto';

interface LeagueProps{
  leagueRankings: LeagueRankingDto;
}

export default function League(props: LeagueProps) {
  const [pos, setPos] = useState <string | null>(props.leagueRankings.lists[0].roundName);
  const [list, setList] = useState < TeamPointsDto[]> (props.leagueRankings.lists[0].teamPoints)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPos: string | null,
  ) => {
    if (newPos !== null){
      setPos(newPos);
      console.log(newPos);
      setList(props.leagueRankings.lists.filter(f => f.roundName === newPos)[0].teamPoints);
    }
  };

  const getShortLeagueName = (id: number): string => {
    
    if (id === 0)
      return 'Σ';
    else if (id < 4)
      return (id) + ".";
    else if (id === 4)
      return '1/8';
      else if (id === 5)
      return '1/4';
    else if (id === 6)
      return '1/2';
    else
      return 'finale i za 3.';
  }

  useEffect(()=>{
    setPos(props.leagueRankings.lists[0].roundName);
    setList(props.leagueRankings.lists[0].teamPoints);
  }, [props.leagueRankings])

    return (
      <Stack  direction={'column'} justifyContent="center" sx={{width:'100%', display:'inline-grid', paddingTop:'15px'}}>
          <ToggleButtonGroup sx={{height:'fit-content', paddingBottom:'10px', maxWidth:'300px'}}
              value={pos}
              exclusive
              onChange={handleChange}
              aria-label="text alignment"
            >

          {props.leagueRankings.lists.sort((a,b) => a.roundId - b.roundId).map((l: RoundRankingDto) => (
            <ToggleButton value={l.roundName}>
              {getShortLeagueName(l.roundId)}
            </ToggleButton>

          ))}
          </ToggleButtonGroup>
          
          {list.map((p: TeamPointsDto) => (
            <Stack direction={'row'} justifyContent='space-between' sx={{minWidth:'300px', maxWidth:'600px'}}>
              <Stack direction={'row'}>
                <Typography>{p.position + ". " + p.name}</Typography>
              </Stack>
              <Typography>{p.points}</Typography>
            </Stack>
          ))}
      </Stack>
      );
    }