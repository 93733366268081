import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

interface TransferDialogProps{
    onDialogClosed: Function;
    info: {title: string, text: string, open: boolean, additional: any | null};
}

export default function TransferDialog(props: TransferDialogProps) {

const handleToClose = () => {
	props.onDialogClosed(true);
};

return (
	<div>

        <Dialog open={props.info.open} onClose={handleToClose}>
            <DialogTitle>{props.info.title}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {props.info.text}
            </DialogContentText>
            {props.info.additional && props.info.additional}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleToClose}
                    color="primary" autoFocus>
                OK
            </Button>
            </DialogActions>
        </Dialog>
	</div>
);
}
