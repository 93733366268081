import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FantasyLogo from '../../assets/img/fantasy-logo-header.png'

interface IntroImageProps{
    hideImage?: boolean;
}

export default function IntroImage(props: IntroImageProps) {

    return  (
    <Stack justifyContent={"center"}>
        <Typography  justifyContent={"center"}
        variant="h5"
        noWrap
        component="a"
        href=""
        sx={{
            mr: 2,
            display: { xs: 'flex' },
            flexGrow: 1,
            fontFamily: 'fantasy',
            fontWeight: 150,
            letterSpacing: '.5rem',
            textDecoration: 'none',
            margin: '0',
            color: '#2257BF'

        }}
        >
        MENADJER
        </Typography>
        <Typography justifyContent={"center"}
        variant="h6"
        noWrap
        component="a"
        href=""
        sx={{
            mr: 2,
            display: { xs: 'flex' },
            flexGrow: 1,
            fontFamily: 'fantasy',
            fontWeight: 200,
            letterSpacing: '.2rem',
            textDecoration: 'none',
            margin: '0',
            color: '#2257BF'

        }}
        >
        - Euro 2024 -
        </Typography>
        {!props.hideImage  && <Stack sx={{width:'100%'}} direction="row" justifyContent="center">
            <img alt="menadjer logo" style={{width:'40%'}} src={FantasyLogo}/>
        </Stack>}
    </Stack>
    
    )
}