import axios from "axios";
import { useAppContext } from "../../context/AuthContext";
import Endpoints from "../../utils/Endpoints";
import { axiosConfig } from "../../utils/Utils";

interface UserControllerType {
  axiosLogout: Function;
  axiosGetUser: Function;
}

export function UserController(): UserControllerType {
  const { authData } = useAppContext();

  const axiosLogout = () => {
    return axios.post(Endpoints.LOGOUT, {}, axiosConfig(authData!.token));
  };

  const axiosGetUser = (token?: string) => {

    return axios.get(Endpoints.USER, axiosConfig( (token && token !== undefined) ? token : authData!.token));
  };

  return {
    axiosLogout,
    axiosGetUser,
  };
}

export type { UserControllerType };
