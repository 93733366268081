import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {Card, CardContent } from '@material-ui/core';
import PlayerDto from '../../../model/player/PlayerDto';
import EnumPlayerPosition from '../../../utils/enum/EnumPlayerPosition';
import { getCountyFlagAvatar } from '../../../utils/CountryColors';
import { EnumPositionStatus, getPlayersOnPosition, getPositionStatus } from '../../../utils/TransferValidation';

interface ChosenPlayerProps{
    onPlayerRemoved: Function;
    players: PlayerDto[]
}

export default function ChosenPlayers(props: ChosenPlayerProps) {

  const handleDelete = (event: Event, player: PlayerDto) => {
    props.onPlayerRemoved(player);
  };

  const getChipInfoStyle = (position: EnumPlayerPosition) => {
    let posStatus: EnumPositionStatus = getPositionStatus(props.players, position);

    switch (posStatus) {
        case EnumPositionStatus.EXCEEDED:
            return {margin:'3px', color:'white', backgroundColor: 'tomato'};
        case EnumPositionStatus.EXACT:
            return {margin:'3px', color:'white', backgroundColor: 'limegreen'};
    default:
        return {margin:'3px'};
    }

  }

  const getPlayerChip = (p: PlayerDto) => {
    return  <Chip 
                key={p.id}
                avatar={getCountyFlagAvatar(p.teamName, {boxShadow: '0 0 2px black'})}
                label={p.name + " (" + (p.cost) + " M)"}
                variant="outlined"
                onDelete={(e) => handleDelete(e, p)}
                sx={{margin:'3px'}}
            />      
  }

  return (
        <Card style={{marginTop:'5px'}}>
            <CardContent>
                <Stack 
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    >
                    <Stack spacing={1}>
                        <Stack direction="row" display='block' >
                        <Chip style={getChipInfoStyle(EnumPlayerPosition.GOALKEEPER)}
                            label={"GOLMANI " + getPlayersOnPosition(props.players, EnumPlayerPosition.GOALKEEPER).length +"/2"}
                            variant="filled"
                        />   
                        {
                            getPlayersOnPosition(props.players, EnumPlayerPosition.GOALKEEPER).map((p:PlayerDto) => getPlayerChip(p))
                        }
                        </Stack>
                        <Stack direction="row" display='block' >
                        <Chip style={getChipInfoStyle(EnumPlayerPosition.DEFENDER)}
                            label={"ODBRANA " + getPlayersOnPosition(props.players, EnumPlayerPosition.DEFENDER).length +"/5"}
                            variant="filled"
                        />   
                        {
                            getPlayersOnPosition(props.players, EnumPlayerPosition.DEFENDER).map((p:PlayerDto) => getPlayerChip(p))
                        }
                        </Stack>
                        <Stack direction="row" display='block' >
                        <Chip style={getChipInfoStyle(EnumPlayerPosition.MIDFIELDER)}
                            label={"SREDINA " + getPlayersOnPosition(props.players, EnumPlayerPosition.MIDFIELDER).length +"/5"}
                            variant="filled"
                        />   
                        {
                            getPlayersOnPosition(props.players, EnumPlayerPosition.MIDFIELDER).map((p:PlayerDto) => getPlayerChip(p))
                        }
                        </Stack>                      
                        <Stack direction="row" display='block' >
                        <Chip style={getChipInfoStyle(EnumPlayerPosition.FORWARD)}
                            label={"NAPAD " + getPlayersOnPosition(props.players, EnumPlayerPosition.FORWARD).length +"/3"}
                            variant="filled"
                        />
                        {
                            getPlayersOnPosition(props.players, EnumPlayerPosition.FORWARD).map((p:PlayerDto) => getPlayerChip(p))
                        }
                        </Stack>
                    </Stack>
                </Stack>

            </CardContent>
        </Card>
  );
}
